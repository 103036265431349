import React from "react";

interface IProps {
  onCommentChange: (comment: string) => void;
}

export class CommentDecisionComponent extends React.Component<IProps, {}> {

  render() {
    return (
      <div data-testid="CommentDecisionComponent" className="mb-4">
        <div className="row">
          <div className="col">
            <div className="text-xlarge text-secondary mb-2">Add a comment</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <textarea data-testid="commentChange" className="form-control form-control-xl mb-2"
                      onChange={e => this.props.onCommentChange(e.target.value)}
                      rows={4}/>
          </div>
        </div>

      </div>
    )
  }
}
