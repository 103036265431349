import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

export const ScrollToTop = withRouter(
  class ScrollToTopWithoutRouter extends React.Component<RouteComponentProps<{}>, {}> {

    componentDidUpdate(prevProps: RouteComponentProps<{}>) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
    }

    render() {
      return this.props.children;
    }
  }
);
