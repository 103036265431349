export enum ConfidentialityLevel {
  STANDARD = 'STANDARD',
  INSIDER = 'INSIDER',
  TREE = 'TREE'
}

export interface IWorkflowStatus {
  status: 'ABORTED'
    | 'IN_PROGRESS'
    | 'RETURN_TO_STUDY'
    | 'FINALLY_APPROVED'
    | 'FINALLY_APPROVED_WITH_MODIFICATION'
    | 'FINALLY_PARTIALLY_APPROVED'
    | 'FINALLY_REFUSED'
    | 'DRAFT';
  date: Date;
}
