export interface ILegalEntityDetails {
  bdrCode: number;
  name: string;
  masterAgreementsComment: string;
  description: string;
  masterAgreements: IMasterAgreement[];
  groupRctCode: string;
  kycPrimaryRole: string;
  leBdrCode: number;
  leName: string;
  subGroupRctCode: string;
  unsecuredLossGivenDefault: string;
}

export interface IMasterAgreement {
  id: string;
  typeCode: string;
  collateralAgreements: ICollateralAgreement[];
}

export interface ICollateralAgreement {
  id?: string;
  masterAgreementId?: string;
  contractTypeCode?: string;
  contractFavor?: string;
  margin?: IMargin;
}

interface IMargin {
  callFrequency?: string;
  externalTrigger?: ITrigger;
}

export interface ITrigger {
  threshold?: IMoney;
  minimalTransfert?: IMoney;
}

export interface IMoney {
  currency: string;
  value: number;
  interval?: string;
}

export interface IDuration {
  [key: string]: number;

  years: number;
  months: number;
  days: number;
}

export const duration = (years: number, months: number, days: number): IDuration => ({
  years,
  months,
  days
});
