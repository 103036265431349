import axios from 'axios';
import * as http from 'http';
import * as https from 'https';

const httpAgent = new http.Agent({keepAlive: true});
const httpsAgent = new https.Agent({keepAlive: true});
export const metricsAxiosInstance = axios.create({
                                                    validateStatus(status) {
                                                      return status >= 200 && status < 300;
                                                    },
                                                    httpAgent,
                                                    httpsAgent
                                                  });
