import { get } from '../../utils/axios/api';
import {
  IFinancingRequest,
  IRequestSummary,
  IRequestVersionsDTO
} from '../models/financing-request-model';
import { IMarketRequest } from '../models/market-request-model';
import { IFinancingLimitRequest } from "../models/financing-limit-request.models";

const DOMAIN = (process.env.NODE_ENV === 'development') ? 'https://cav-hom-approval-request.fr.world.socgen' : '/approval-request';
export const FINANCING_REQUEST_BASE_URL = 'api/v1/financing-requests';
export const MARKET_REQUEST_BASE_URL = 'api/v1/market-requests';
export const FINANCING_LIMIT_REQUEST_AW_BASE_URL = 'api/v1/annual-limits/amendment-waiver-without-modification'
export const FINANCING_LIMIT_REQUEST_BASE_URL = 'api/v1/annual-limits'
export const REQUEST_BASE_URL = 'api/v1/requests';

export class RequestResource {

  domain: string;

  constructor(domain = DOMAIN) {
    this.domain = domain;
  }

  getFinancingRequest = async (requestCode: string): Promise<IFinancingRequest> => {
    try {
      const response = await get(`${this.domain}/${FINANCING_REQUEST_BASE_URL}/${requestCode}`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getMarketRequest = async (requestCode: string): Promise<IMarketRequest> => {
    try {
      const response = await get(`${this.domain}/${MARKET_REQUEST_BASE_URL}/${requestCode}`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getFinancingLimitRequest = async (requestCode: string, type ?: string): Promise<IFinancingLimitRequest> => {
    try {
      const response = await get(`${this.domain}/${ (type && type === 'AMENDMENT/WAIVER') ? FINANCING_LIMIT_REQUEST_AW_BASE_URL : FINANCING_LIMIT_REQUEST_BASE_URL}/${requestCode}`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getFinancingRequestVersionDetails = async (requestCode: string): Promise<IRequestVersionsDTO> => {
    try {
      const response = await get(`${this.domain}/${FINANCING_REQUEST_BASE_URL}/${requestCode}/versions`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getMarketRequestVersionDetails = async (requestCode: string): Promise<IRequestVersionsDTO> => {
    try {
      const response = await get(`${this.domain}/${MARKET_REQUEST_BASE_URL}/${requestCode}/versions`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getRequestSummary = async (requestCode: string): Promise<IRequestSummary> => {
    try {
      const response = await get(`${this.domain}/${REQUEST_BASE_URL}/${requestCode}/summary`);
      return response.data;
    } catch (error) {
      return error;
    }
  }

}

export const requestResource = new RequestResource();
