import { isAfter } from 'date-fns';
import { Decision } from '../../components/App/Decision/MakeDecision/SelectDecision/SelectDecision';
import { Delegation } from '../models/delegation';
import IRequestDecision from '../models/IRequestDecision';
import { IPotentialDecisionMaker } from '../models/potential-decision-maker';
import { IUser } from '../models/user';
import { decisionResource } from '../resources/decision.resource';
import {RequestType} from "../models/request-type";

export const getLastDecision = async (requestCode: string): Promise<IRequestDecision | undefined> => {
  const decisions = await decisionResource.getAllDecision(requestCode);
  return decisions.length > 0 ? decisions
    .reduce((prev: IRequestDecision, current: IRequestDecision) =>
              isAfter(new Date(prev.givenOn), new Date(current.givenOn))? prev : current) : undefined
};

export function submitDecision(requestCode: string,
                               requestVersion: number,
                               workflowId: string,
                               delegation: Delegation,
                               comment: string,
                               decisionMaker: IPotentialDecisionMaker,
                               currentUser: IUser,
                               decision: Decision,
                               lastDecisionId?: string): Promise<any> {

  const representedUsername = decisionMaker.username !== currentUser.username ? decisionMaker.username : undefined;
  if (lastDecisionId !== null && lastDecisionId !== undefined) {
    return decisionResource.submitDecisionBasedOnLatestDecision(requestCode, lastDecisionId, workflowId, delegation, comment, decision, representedUsername);
  }
  return decisionResource.submitFirstDecision(requestCode, requestVersion, workflowId, delegation, comment, decision, representedUsername);
}

export function submitDecisionOnPostApproval(requestCode: string,
                               requestVersion: number,
                               workflowId: string,
                               delegation: Delegation,
                               comment: string,
                               decisionMaker: IPotentialDecisionMaker,
                               currentUser: IUser,
                               decision: Decision): Promise<any> {

  const representedUsername = decisionMaker.username !== currentUser.username ? decisionMaker.username : undefined;
  return decisionResource.submitDecisionOnPostApproval(requestCode, requestVersion, workflowId, delegation, comment, decision, representedUsername);
}

export function isPostApprovalDecision(context: any): boolean{
  return (context.request.type === 'AMENDMENT/WAIVER' && context.requestType === RequestType.FINANCING_LIMIT) ;
}
