import { defaultEnhancers, EnhanceWithPageDetails, Queue, QueueLogger, WebTracker } from 'web-tracker-library';
import history from '../../context/History';
import ClickEventTracker from './ClickEventTracker';
import { metricsLogger } from './metrics-logger.resource';
import RouteEventTracker from './RouteEventTracker';

class WebTrackerService {

  webTracker: WebTracker;
  routeEventTracker: RouteEventTracker;
  clickEventTracker: ClickEventTracker;

  constructor() {
    const actionsBeforeUnload: any = [];
    const queueLogger = new QueueLogger(metricsLogger, new Queue(2));

    const pageDetailsProvider = () => {
      const pageUrl = history.location.pathname;
      return {pageName: pageUrl, pageUrl};
    };

    const enhancers = [...defaultEnhancers, new EnhanceWithPageDetails(pageDetailsProvider)];
    this.webTracker = new WebTracker("cav-mycredit-mobile-approver-front", queueLogger, enhancers, actionsBeforeUnload);
    this.routeEventTracker = new RouteEventTracker(this.webTracker, (new Date()).getTime());
    this.clickEventTracker = new ClickEventTracker(this.webTracker);

    actionsBeforeUnload.push(() => this.trackLeaveApp());
    actionsBeforeUnload.push(() => queueLogger.sendCurrentEvents());
  }

  track(evtName: string, eventData: any) {
    this.webTracker.track(evtName, eventData)
  }

  trackClick(el: any) {
    this.clickEventTracker.trackClick(el);
  }

  trackEnterPage() {
    this.routeEventTracker.trackPageEntering();
  }

  trackLeaveApp() {
    this.routeEventTracker.trackPageLeaving();
  }
}

const webTrackerService = new WebTrackerService();
export default webTrackerService;
