import {Decision} from '../../components/App/Decision/MakeDecision/SelectDecision/SelectDecision';
import {get, post} from '../../utils/axios/api';
import {Delegation} from '../models/delegation';
import IRequestDecision from '../models/IRequestDecision';
import {ICeilingDecisionCommand} from "../models/financing-limit-request.models";
import {ILatestDecision} from "../models/latest-decision-model";
import {userResource} from "./user.resource"

const DOMAIN = (process.env.NODE_ENV === 'development') ? 'https://cav-hom-decision.fr.world.socgen/api/v1/decisions' : '/decision';

export class DecisionResource {

  domain: string;

  constructor(domain = DOMAIN) {
    this.domain = domain
  }

  getAllDecision = async (requestCode: string): Promise<IRequestDecision[]> => {
    try {
      const response = await get(`${this.domain}/${requestCode}`);
      return response.data
    } catch (error) {
      return error;
    }
  };

  submitFirstDecision = async (requestCode: string,
                               requestVersion: number,
                               workflowId: string,
                               delegation: Delegation,
                               comment: string,
                               decision: Decision,
                               representedUsername?: string): Promise<any> => {
    const decisionCommand = {
      requestCode,
      requestVersion,
      workflowId,
      delegation,
      comment,
      decision,
      representedUsername,
    };
    return post(`${this.domain}/submit-first-decision`, decisionCommand);
  };

  submitDecisionOnPostApproval = async (requestCode: string,
                                        requestVersion: number,
                                        workflowId: string,
                                        delegation: Delegation,
                                        comment: string,
                                        decision: Decision,
                                        representedUsername?: string): Promise<any> => {
    const decisionCommand = {
      requestCode,
      requestVersion,
      workflowId,
      delegation,
      comment,
      decision,
      representedUsername,
    };
    return post(`${this.domain}/submit-decision-on-post-approval`, decisionCommand);
  };

  submitDecisionBasedOnLatestDecision = async (requestCode: string,
                                               lastDecisionId: string,
                                               workflowId: string,
                                               delegation: Delegation,
                                               comment: string,
                                               decision: Decision,
                                               representedUsername?: string): Promise<any> => {
    const decisionCommand = {
      requestCode,
      lastDecisionId,
      workflowId,
      delegation,
      comment,
      decision,
      representedUsername,
    };
    return post(`${this.domain}/submit-decision-based-on-latest-decision`, decisionCommand);
  }

  getCeilingDecisionHistory = async (requestId: string): Promise<ICeilingDecisionCommand | undefined> => {
    try {
      const response = await get(`${this.domain}/${requestId}`);
      return (response.data.length > 0 && response.data[0] ? response.data[0].ceilingDecision : undefined);
    } catch (error) {
      return error;
    }
  }
  getApprovableQuantitiesByDecisionTypeForLatestDecision = async (requestId: string): Promise<Map<string, number>> => {
    try {
      const response = await get(`${this.domain}/latest-decision-types-counter/${requestId}`);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  getLatestDecision = async (requestId: string): Promise<ILatestDecision> => {
    try {
      const response = await get(`${this.domain}/${requestId}/latest`);
      if ( response.data ) {
        const userInfo = await userResource.getUserBasicInfo(response.data.givenBy)
        return {
          requestDecisionId: response.data.id,
          fullName: userInfo.fullName,
          teamLabel: userInfo.teamLabel,
          time: response.data.givenOn,
        }
      } else {
        return {
          requestDecisionId: undefined,
          fullName: '',
          teamLabel: '',
          time: ''
        }
      }
    } catch (error) {
      return error;
    }
  };
}

export const decisionResource = new DecisionResource();
