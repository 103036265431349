import { WebTracker } from "web-tracker-library";

export default class RouteEventTracker {

  enterPage: number;
  webTracker: WebTracker;

  constructor(webTracker: WebTracker, enterPage: number) {
    this.webTracker = webTracker;
    this.enterPage = enterPage;
  }

  trackPageEntering() {
    this.webTracker.track("EnterPage", {});
  }

  trackPageLeaving() {
    const leaveAt = new Date().getTime();
    const enterAt = this.enterPage;
    const stayDuration = leaveAt - enterAt;
    this.webTracker.track("LeavePage", {enterAt, stayDuration, leaveAt});
  }

}
