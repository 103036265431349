export interface IPotentialDecisionMaker {
  username: string;
  fullName: string;
  teamId: string;
  teamLabel: string;
  hasSigningAuthority: boolean;
  hasCreditAuthority: boolean;
  isFinalApprover: boolean;
}

export const defaultDecisionMaker: IPotentialDecisionMaker = {
  username: '',
  fullName: '',
  teamId: '',
  teamLabel: '',
  hasSigningAuthority: false,
  hasCreditAuthority: false,
  isFinalApprover: false,
};
