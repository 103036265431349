import { IFinancingRequest } from '../models/financing-request-model';
import { IMarketRequest } from '../models/market-request-model';
import { ILine } from '../models/request-model';
import { RequestType } from '../models/request-type';
import {ICeiling, IFinancingLimitRequest} from "../models/financing-limit-request.models";

export const getRequestVersion = (requestType: RequestType, request: IFinancingRequest | IMarketRequest | IFinancingLimitRequest): number =>
  requestType === RequestType.FINANCING
  ? (request as IFinancingRequest).requestVersion
  : requestType === RequestType.FINANCING_LIMIT
    ? (request as IFinancingLimitRequest).requestVersion
    :(request as IMarketRequest).version;

export const totalLines = ({marketLines, financingLines, financingLimits}: {financingLines?: ILine[], marketLines: ILine[], financingLimits: ILine[]}): number =>
  (marketLines ? marketLines.length : 0) + (financingLines ? financingLines.length : 0) + (financingLimits ? financingLimits.length : 0);

export const totalCeiling = ({ceiling}: {ceiling?: ICeiling}): number =>
  (ceiling ? 1 : 0);
