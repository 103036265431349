import React from "react";
import { navigate } from "../../../../utils/util.service";

export default class MakeDecisionButton extends React.Component {

  render() {
    return (
      <div className="row">
        <div className="col">
          <button data-testid="MakeDecisionButton" data-tracking-tag="mobile-make-decision" className="btn btn-xl btn-primary btn-text-icon mt-4 mb-2" onClick={() => goToDecisionPage()}>
            Make your decision
            <i className="icon icon-sm">arrow_forward</i>
          </button>
        </div>
      </div>
    )
  }

}

function goToDecisionPage() {
  navigate("/decision");
}
