import React from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-mini-footer': any;
    }
  }
}

export const MiniFooter: React.FC = () => {
  return (
    <sgwt-mini-footer
      type='compact'
      design='light'
      container='container'
      mode='sg-markets'
      contact-us-by-help-center='sgwt-help-center'
      style={{position: "absolute", width: "100%", bottom: "0"}}/>
  );
};
