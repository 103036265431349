import * as React from 'react';

export default class AccessDeniedComponent extends React.Component {
  render() {
    return (
      <div className="container px-4">
        <div className="alert alert-outline-primary my-5 d-flex" role="alert">
          <i className="icon icon-md mr-2 line-height-1">block</i>
          <div>
            <h4 className="alert-heading">You don't have access to the content of this request.</h4>
            <div className="text-large">If you think you should have access to it, please contact the request initiator or create
              a support ticket as a last resort
            </div>
          </div>
        </div>
      </div>
    )
  }
}
