import { ConfidentialityLevel } from '../models/workflow-model';
import { workflowResource } from '../resources/workflow.resource';
import {visibilityResource} from "../resources/visibility.resource";

export const hasAccess = async (username: string, requestCode: string) => {
  const [confidentialityLevel, hasAccessResponse] = await Promise.all([
    workflowResource.getConfidentialityLevel(requestCode),
    visibilityResource.hasAccessToRequest(username, requestCode)]);
  return (confidentialityLevel === ConfidentialityLevel.STANDARD && hasAccessResponse);
}
