import { IUser, IUserBasicInfo } from "../models/user";
import { get } from "../../utils/axios/api";

const DOMAIN = (process.env.NODE_ENV === 'development') ? 'https://cav-hom-user-referential.fr.world.socgen' : '/user-referential';
export const CURRENT_USER_BASE_URL = "api/v1/current-user";
export const USER_BASE_URL = "api/v1/users";

export class UserResource {

  domain: string;

  constructor(domain = DOMAIN) {
    this.domain = domain
  }

  getCurrentUser = async(): Promise<IUser> => {
    try {
      const response = await get(`${this.domain}/${CURRENT_USER_BASE_URL}`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getUserBasicInfo = async(username: string): Promise<IUserBasicInfo> => {
    try {
      const response = await get(`${this.domain}/${USER_BASE_URL}/${username}/basic-info`);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}

export const userResource = new UserResource();
