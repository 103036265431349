export interface ILatestDecision {
  requestDecisionId?: string;
  fullName: string;
  teamLabel: string;
  time: string;
}

export const emptyLatestDecision = {
  fullName: "",
  teamLabel: "",
  time: ""
};
