import React from 'react';
import { GlobalContext } from '../../../../context/global.context';
import { emptyLatestDecision, ILatestDecision } from '../../../../domain/models/latest-decision-model';
import { RequestType } from '../../../../domain/models/request-type';
import { decisionResource } from '../../../../domain/resources/decision.resource';
import { withLoadingComponent } from '../../common/WithLoadingComponent';
import FinancingRequestSummaryComponent from './FinancingRequestSummary/FinancingRequestSummaryComponent';
import InitialRequestComponent from './InitialRequest/InitialRequestComponent';
import LastDecisionComponent from './LastDecision/LastDecisionComponent';
import MarketRequestSummaryComponent from './MarketRequestSummary/MarketRequestSummaryComponent';
import ObligorComponent from './Obligor/ObligorComponent';
import FinancingLimitRequestSummaryComponent
  from "./FinancingLimitRequestSummary/FinancingLimitRequestSummaryComponent";

interface IState {
  loaded: boolean;
  latestDecision: ILatestDecision;
}

export default class OverviewComponent extends React.Component<{}, IState> {

  static contextType = GlobalContext;

  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      latestDecision: emptyLatestDecision,
    }
  }

  async componentDidMount() {
    const latestDecision = await decisionResource.getLatestDecision(this.context.request.code);
    this.setState({latestDecision, loaded: true});
  }

  render() {
    const OverviewWithLoading = withLoadingComponent(() => this.overviewRenderer());
    return <OverviewWithLoading loaded={this.state.loaded}/>;
  }

  private overviewRenderer() {
    return (
      <div>
        {
          !!this.state.latestDecision.requestDecisionId ? <LastDecisionComponent latestDecision={this.state.latestDecision}/> : <InitialRequestComponent/>
        }
        {
          this.context.requestType === RequestType.FINANCING
            ? <FinancingRequestSummaryComponent/>
            : this.context.requestType === RequestType.FINANCING_LIMIT
            ? <FinancingLimitRequestSummaryComponent/>
            :<MarketRequestSummaryComponent/>
        }
        <ObligorComponent/>
      </div>
    );
  }
}
