import React from 'react';

interface IProps {
  reasons: string[];
}

export class AlertDecision extends React.Component<IProps, {}> {

  private title = "Some information is missing";

  render() {
    return (
      <div data-testid="AlertDecision" className="alert alert-outline-danger text-large text-danger mb-3">
        <div className="row">
          <div className="col">
            <div className="font-weight-bold">{this.title}</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div>{this.props.reasons && this.props.reasons.map((reason) => (<div key={reason}>- {reason}</div>))}</div>
          </div>
        </div>
      </div>
    )
  }
}
