import { IPotentialDecisionMaker } from '../models/potential-decision-maker';
import { ConfidentialityLevel } from '../models/workflow-model';
import { get } from '../../utils/axios/api';

const DOMAIN = (process.env.NODE_ENV === 'development') ? 'https://cav-hom-approval-workflow.fr.world.socgen' : '/approval-workflow';
export const BASE_URL = 'api/v1/workflows';

export class WorkflowResource {

  domain: string;

  constructor(domain = DOMAIN) {
    this.domain = domain
  }

  canGiveDecision = async (workflowId: string): Promise<boolean> => {
    try {
      const response = await get(`${this.domain}/${BASE_URL}/${workflowId}/decision-right`);
      return response.data.hasRight;
    } catch (error) {
      return error;
    }
  };

  getPotentialDecisionMakers = async (workflowId: string): Promise<IPotentialDecisionMaker[]> => {
    try {
      const response = await get(`${this.domain}/${BASE_URL}/${workflowId}/potential-decision-makers-linked-to-current-user`);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  getConfidentialityLevel = async(requestCode: string): Promise<ConfidentialityLevel> => {
    try {
      const response = await get(`${this.domain}/${BASE_URL}/confidentiality-level`, {'requestCode': requestCode});
      return response.data;
    } catch (error) {
      return error;
    }
  };

}

export const workflowResource = new WorkflowResource();
