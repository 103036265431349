import * as React from "react";
import { ILatestDecision } from "../../../../../domain/models/latest-decision-model";

interface IProps {
  latestDecision: ILatestDecision;
}

export default class LastDecisionComponent extends React.Component<IProps> {

  render() {
    return (
      <div className="alert alert-outline-primary text-large mt-4">
        <div className="row">
          <div className="col">
            <div>This is the request as validated by last approver <b>{this.props.latestDecision.fullName} ({this.props.latestDecision.teamLabel})</b></div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="text-info d-flex align-content-center mt-2">
              <span className="icon icon-md mr-2 line-height-1">info_outline</span>
              <span className="align-top">Modifications are marked in blue</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
