import { getTimeInMilliSeconds } from '../../utils/conversion.service';
import { IFinancingRequest, IFinancingRequestStatus } from '../models/financing-request-model';

export function getFinancingRequestStatus({workflowStatus, risqApprovalValidity, actualSigning, abandonDate, dealStatus, requestStatus, finalApprovalDeadline}: IFinancingRequest): IFinancingRequestStatus {
  const workflowStatusDate = new Date(workflowStatus.date);
  const workflowStatusDeadline = new Date(workflowStatusDate.getTime() + getTimeInMilliSeconds(risqApprovalValidity));
  const status = dealStatus === 'Abandoned'
                 ? 'ABANDONED'
                 : requestStatus === 'RECALLED'
                   ? requestStatus
                   : workflowStatus
                     ? workflowStatus.status
                     : undefined;
  switch (status) {
    case 'ABANDONED':
      return {
        status: 'ABANDONED',
        date: !!abandonDate ? new Date(abandonDate) : undefined
      };
    case 'RECALLED':
    case 'FINALLY_REFUSED':
    case 'DRAFT':
      return {
        status
      };
    case 'IN_PROGRESS':
      return {
        status: 'IN_PROGRESS',
        date: !!finalApprovalDeadline ? new Date(finalApprovalDeadline) : undefined
      };
    case 'RETURN_TO_STUDY':
      return {
        status: 'RETURN_TO_STUDY',
        date: workflowStatusDate
      };
    case 'FINALLY_APPROVED':
    case 'FINALLY_APPROVED_WITH_MODIFICATION':
    case 'FINALLY_PARTIALLY_APPROVED':
      return {
        status,
        date: new Date(actualSigning ? actualSigning : workflowStatusDeadline),
        isSigning: !!actualSigning,
        deadLine: workflowStatusDeadline
      };
    default:
      throw Error('Unable to build financing request status');
  }
}
