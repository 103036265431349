import React from "react";

export class HomePageComponent extends React.Component<{}, {}> {

  render() {

    return (
      <div className="container px-4">
        <div className="row">
          <div className="col">
            <h1 className="mb-3 mt-5 display-3">Welcome to MyCredit Mobile Approver</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="h4 text-secondary mb-5">This service allows you to make a decision on a credit file from your mobile phone.</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="alert alert-outline-info text-xlarge mb-2 p-3">To use the service, open a credit file from any MyCredit email notification you received in Boxer.</div>
          </div>
        </div>
      </div>
    )
  }
}
