import React from "react";

export default class PageNotFoundComponent extends React.Component {

  render() {
    return (
      <div data-testid="PageNotFoundComponent" className="container-fluid">
        <div className="container main-wrapper">
          <div className="row">
            <div className="col-lg-2 bg-white pt-5 pb-4 py-lg-0">
                  <span className="d-block text-outline-socgen text-primary-alt font-weight-medium"
                        style={{fontSize: "56px", fontFamily: "Neue Haas Grotesk W04 Disp"}}>404</span>
            </div>
            <div className="col-lg-5 bg-white d-flex flex-column justify-content-between">
              <div>
                <h1 className="display-3 font-weight-medium line-height-1 py-2">Page not found.</h1>
                <p className="mb-5 h3 font-weight-medium text-secondary line-height-1">Sorry, we couldn't
                  find
                  this page</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
