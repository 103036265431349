import { IDuration } from "../domain/models/financing-request-model";

export enum Multiple {
  BILLION = 1000000000,
  MILLION = 1000000,
  KILO = 1000,
}

export enum NumberAbbreviation {
  KILO_LETTER = " K",
  MILLION_LETTER = " M",
  BILLION_LETTER = " B",
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function abbreviateNumber(amount: number): string {
  let formattedAmount;
  if (amount && Math.abs(amount)) {
      if (Math.abs(amount) >= Multiple.BILLION) {
      formattedAmount = amount / Multiple.BILLION;
      formattedAmount = parseFloat(parseFloat(formattedAmount.toString()).toFixed(2)).toString() + NumberAbbreviation.BILLION_LETTER;
    } else if (Math.abs(amount) >= Multiple.MILLION) {
      formattedAmount = amount / Multiple.MILLION;
      formattedAmount = parseFloat(parseFloat(formattedAmount.toString()).toFixed(2)).toString() + NumberAbbreviation.MILLION_LETTER;
    } else if (Math.abs(amount) >= Multiple.KILO) {
      formattedAmount = amount / Multiple.KILO;
      formattedAmount = parseFloat(parseFloat(formattedAmount.toString()).toFixed(2)).toString() + NumberAbbreviation.KILO_LETTER;
    } else {
      return parseFloat(parseFloat("" + amount).toFixed(2)).toString();
    }
  } else {
    return "0";
  }
  return formattedAmount;
}

export function getTimeInMilliSeconds(tenor: IDuration) {
  return calculateTenorInDays(tenor) * 24 * 60 * 60 * 1000;
}

function calculateTenorInDays(tenor: IDuration) {
  return (tenor.years ? tenor.years * 365 : 0) + (tenor.months ? tenor.months * 30 : 0) +
         (tenor.days ? tenor.days : 0);
}

export function formatDateToString(date: Date | string): string {
  const newDate = new Date(date);
  return formatDateOfMonth(newDate.getDate()) + " " + months[newDate.getMonth()] + " " + newDate.getFullYear();
}

function formatDateOfMonth(dateOfMonth: number): string {
  return dateOfMonth < 10 ? "0" + dateOfMonth.toString() : dateOfMonth.toString();
}
