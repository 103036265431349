import { defaultContext, IGlobalContext } from '../../context/global.context';
import { RequestType } from '../models/request-type';
import { requestResource } from '../resources/request.resource';
import { userResource } from '../resources/user.resource';

export const loadGlobalContext = async (requestCode: string): Promise<IGlobalContext> => {

  if (!!requestCode) {
    const [{sourceType: requestType, type}, currentUser] =
      await Promise.all([requestResource.getRequestSummary(requestCode), userResource.getCurrentUser()]);
    const request = await (requestType === RequestType.FINANCING
      ? requestResource.getFinancingRequest(requestCode)
      : requestType === RequestType.FINANCING_LIMIT
        ? requestResource.getFinancingLimitRequest(requestCode,type)
        : requestResource.getMarketRequest(requestCode));
    const initiator = await userResource.getUserBasicInfo(request.initiatorUserId);
    return {request, currentUser, initiator, requestType}
  }
  else {
    const currentUser = await userResource.getCurrentUser();
    return {...defaultContext, currentUser}
  }
};
