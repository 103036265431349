export interface IUser {
  username: string;
  email: string;
}

export interface IUserBasicInfo {
  fullName: string;
  teamLabel: string;
}

export const emptyUser = {
  username: "",
  email: ""
};

export const emptyUserBasicInfo = {
  fullName: "",
  teamLabel: "",
};
