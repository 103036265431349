import axios from "axios";
import { ErrorType } from "../../components/App/common/ErrorType";
import { redirectToErrorPage } from "../util.service";

export const axiosInstance = axios.create({
                                            validateStatus(status) {
                                              return status >= 200 && status < 300;
                                            },
                                          });

axiosInstance.interceptors.response.use(
  undefined,
  error => errorHandler(error)
);

export const errorHandler = (error: any) => {
  if (error.response.status === 404) {
    redirectToErrorPage(ErrorType.NOT_FOUND);
  }
  if (error.response.status === 500) {
    redirectToErrorPage(ErrorType.INTERNAL_ERROR);
  }
  if (error.response.status === 503) {
    redirectToErrorPage(ErrorType.UNAVAILABLE_SERVICE);
  }
  return Promise.reject(error);
};
