import React from "react";
import { LoadingSpinner } from "./LoadingSpinner";

interface IProps {
  loaded: boolean;
}

export const withLoadingComponent = <P extends object>(Component: React.ComponentType<P>) =>
  class WithLoadingComponent extends React.Component<P & IProps> {
    render() {
      const {loaded, ...props} = this.props;
      if (!loaded) {
        return <LoadingSpinner/>;
      }
      return <Component {...props as P}/>;
    }
  };
