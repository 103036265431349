import { AxiosPromise } from 'axios';
import * as querystring from 'querystring';
import { getConf } from './axios-conf';
import { axiosInstance } from './axios-instance';

export function get(url: string, params?: any): AxiosPromise {
  const conf = getConf();
  const urlParamsSerializer = (urlParams: any) => {
    return querystring.stringify(urlParams)
  };
  return axiosInstance.get(url, {
    ...conf,
    params,
    paramsSerializer: urlParamsSerializer
  });
}

export function post(url: string, body: any): AxiosPromise {
  return axiosInstance.post(url, body, getConf());
}
