import * as React from "react";
import { GlobalContext } from "../../../../../context/global.context";

export default class InitialRequestComponent extends React.Component{

  static contextType = GlobalContext;

  render() {
    return (
      <div className="alert alert-outline-primary text-large mt-4">
        <div className="row">
          <div className="col">
            <div>This is the request as originated by the initiator <b>{this.context.initiator.fullName} ({this.context.initiator.teamLabel})</b></div>
          </div>
        </div>
      </div>
    )
  }

}
