import { IWorkflowStatus } from './workflow-model';

export interface IRequest {
  code: string;
  name: string;
  workflowId: string;
  createdOn: Date;
  workflowStatus: IWorkflowStatus;
  initiatorUserId: string;
  obligor?: IObligor;
  type: string;
}

export interface ILine {
  code: string;
}

export interface IObligor {
  parentCompany?: IObligorEntity
  company?: IObligorEntity;
  type: ObligorType;
}

export enum ObligorType {
  SUBSIDIARY = 'SUBSIDIARY',
  ORPHAN_ENTITY = 'ORPHAN_ENTITY',
  MAIN_COMPANY = 'MAIN_COMPANY',
  LEGAL_ENTITY = 'LEGAL_ENTITY'
}

interface IObligorEntity {
  name: string;
  onWatchList: boolean;
  ratings: ILegalEntityRatings;
  lodProposal?: ITrendedRating;
  pcruName?: string;
}

interface ILegalEntityRatings {
  foreignAndLocalCurrencies?: ITrendedRatingWithValidity;
}

interface ITrendedRatingWithValidity {
  value?: string;
  validity?: Date;
}

interface ITrendedRating {
  value?: string;
}
