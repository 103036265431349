import React from "react";
import { GlobalContext } from "../../../../../context/global.context";
import { ILatestDecision } from "../../../../../domain/models/latest-decision-model";

export enum Decision {
  AGREE = "AGREE",
  REFUSE = "REFUSE",
  RETURN_TO_STUDY = "RETURN_TO_STUDY",
  UNDEFINED = "UNDEFINED"
}

interface IProps {
  latestDecision: ILatestDecision;
  handleDecisionChoice: (decision: Decision) => void;
}

interface IState {
  decision: Decision;
}

export class SelectDecisionComponent extends React.Component<IProps, IState> {

  static contextType = GlobalContext;

  constructor(props: IProps) {
    super(props);
    this.state = {
      decision: Decision.UNDEFINED,
    };
    this.toggleSelection = this.toggleSelection.bind(this);
  }

  render() {
    return (
      <div data-testid="SelectDecisionComponent" className="mb-4">

        <div className="row">
          <div className="col">
            <div className="text-xlarge text-secondary mb-2">Select one of the 3 options below:</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div data-testid="agreeSelector"
                 data-tracking-tag="mobile-agree"
                 className={this.getAgreeCardClass()}
                 style={{cursor: "pointer"}}
                 onClick={() => this.toggleSelection(Decision.AGREE)}>
              <div className="d-flex">
                {this.getAgreeCheckedIcon()}
                {!!this.props.latestDecision.requestDecisionId ? this.getLatestDecisionForAgreeCardRenderer() : this.getInitiatorDataForAgreeCardRenderer()}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div data-testid="refuseSelector"
                 data-tracking-tag="mobile-refuse"
                 className={this.getRefuseCardClass()}
                 style={{cursor: "pointer"}}
                 onClick={() => this.toggleSelection(Decision.REFUSE)}>
              <div className="d-flex">
                {this.getRefuseCheckedIcon()}
                <div>
                  <div className="text-large text-danger font-weight-bold">I refuse the whole request</div>
                  <div className="text-large">All facilities/limits and conditions will be refused</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div data-testid="returnToStudySelector"
                 data-tracking-tag="mobile-return-study"
                 className={this.getReturnToStudyCardClass()}
                 style={{cursor: "pointer"}}
                 onClick={() => this.toggleSelection(Decision.RETURN_TO_STUDY)}>
              <div className="d-flex">
                {this.getReturnCheckedIcon()}
                <div>
                  <div className="text-large text-secondary font-weight-bold">I return the request to study</div>
                  <div className="text-large">Workflow will be stopped and request sent back to the initiator</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }

  private getLatestDecisionForAgreeCardRenderer() {
    return (
      <div>
        <div className="text-large text-success font-weight-bold">I do agree with the last decision</div>
        <div className="text-large">made by {this.props.latestDecision.fullName} ({this.props.latestDecision.teamLabel})</div>
      </div>
    )
  }

  private getInitiatorDataForAgreeCardRenderer() {
    return (
      <div>
        <div className="text-large text-success font-weight-bold">I do agree with the request</div>
        <div className="text-large">originated by {this.context.initiator.fullName} ({this.context.initiator.teamLabel})</div>
      </div>
    )
  }

  private toggleSelection(decision: Decision) {
    const newDecision = this.state.decision === decision ? Decision.UNDEFINED : decision;
    this.setState({decision: newDecision});
    this.props.handleDecisionChoice(newDecision);
  }

  private getAgreeCardClass = () => "card card-bordered text-xlarge my-2 p-3 border border-success " +
                                    (this.state.decision === Decision.AGREE ? "bg-success-alpha-md border-lg" : "");

  private getRefuseCardClass = () => "card card-bordered text-xlarge my-2 p-3 border border-danger " +
                                    (this.state.decision === Decision.REFUSE ? "bg-danger-alpha-md border-lg" : "");

  private getReturnToStudyCardClass = () => "card card-bordered text-xlarge my-2 p-3 border border-secondary " +
                                     (this.state.decision === Decision.RETURN_TO_STUDY ? "bg-secondary-alpha-md border-lg" : "");

  private getAgreeCheckedIcon = () =>
    (<i className="text-success icon icon-md line-height-1 mr-2">{this.state.decision === Decision.AGREE ? "radio_button_checked" : "radio_button_unchecked"}</i>);

  private getRefuseCheckedIcon = () =>
    (<i className="text-danger icon icon-md line-height-1 mr-2">{this.state.decision === Decision.REFUSE ? "radio_button_checked" : "radio_button_unchecked"}</i>);

  private getReturnCheckedIcon = () =>
    (<i className="text-secondary icon icon-md line-height-1 mr-2">{this.state.decision === Decision.RETURN_TO_STUDY ? "radio_button_checked" : "radio_button_unchecked"}</i>);

}
