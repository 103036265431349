import { ClickTracker, WebTracker } from "web-tracker-library";

export default class ClickEventTracker {

  clickTracker: ClickTracker;

  constructor(webTracker: WebTracker) {
    this.clickTracker = new ClickTracker(webTracker);
  }

  trackClick(el: any) {
    if (!el) {
      return;
    }
    const attributeName = "data-tracking-tag";
    if (el.hasAttribute(attributeName)) {
      const title = el.getAttribute(attributeName);
      const link = el.getAttribute("href");
      this.clickTracker.track({title, link});
    } else {
      this.trackClick(el.parentElement);
    }
  }

}

