import React from "react";

export class DesktopPageComponent extends React.Component {

  render() {
    return (
      <div data-testid="DesktopPageComponent" className="container-fluid">
        <div className="container main-wrapper text-center">

          <div className="spacing-m-3"/>

          <div className="row">
            <div className="col">
              <i className="text-secondary icon icon-lg">desktop_access_disabled</i>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <p className="text-xxlarge text-secondary font-weight-medium">This service is optimized for mobile use!</p>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <p className="text-secondary">On desktop, please connect to the standard MyCredit desktop application from SG internal network.</p>
            </div>
          </div>

        </div>
      </div>
    )
  }

}
