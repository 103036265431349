import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { injectSGWTIntl, ISGWTIntlHOCProps } from '@sgwt/intl-react';

import { IPropsWithGlobalContext, withGlobalContext } from '../../../context/global.context';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-account-center': {
        authentication?: 'sg-connect-v1' | 'sg-connect-v2';
        mode?: 'sg-markets';
        debug?: string;
      };
    }
  }
}
export const AccountCenter = withGlobalContext(
  injectIntl(
    injectSGWTIntl(
      class extends React.Component<IPropsWithGlobalContext & InjectedIntlProps & ISGWTIntlHOCProps> {

        render() {
          return (
            <>
              <sgwt-account-center
                hide-services-link
                authentication='sg-connect-v2'
              />
            </>
          );
        }
      }
    )
  )
);
