import React from 'react';
import {GlobalContext} from '../../../../context/global.context';
import {DecisionType} from '../../../../domain/models/decision-type';
import {ILatestDecision} from '../../../../domain/models/latest-decision-model';
import {formatDateToString} from '../../../../utils/conversion.service';
import {ICeilingDecisionCommand} from "../../../../domain/models/financing-limit-request.models";
import {RequestType} from "../../../../domain/models/request-type";

interface IProps {
  latestDecision: ILatestDecision;
  decisionTypesCounter: Map<string, number>;
  ceilingDecision?: ICeilingDecisionCommand;
}

export class LastDecisionDetailsComponent extends React.Component<IProps, {}> {

  static contextType = GlobalContext;
  private AMENDMENT_WAIVER = 'AMENDMENT/WAIVER';
  private DEAL_ANNUAL_REVIEW = 'DEAL_ANNUAL_REVIEW';

  render() {

    return (
      <div data-testid="LastDecisionDetailsComponent">
        <div className="row">
          <div className="col">
            <h3 className="mb-1 mt-4">1. Check the last decision</h3>
          </div>
        </div>

        <div className="card card-bordered my-2 px-3 pt-3 pb-2 text-large">
          <div className="row mb-4">
            <div className="col-12">
              <div className="text-secondary font-weight-medium">Approver</div>
              <div>{this.props.latestDecision.fullName} ({this.props.latestDecision.teamLabel})<br/> {formatDateToString(this.props.latestDecision.time)}
              </div>
            </div>
          </div>
          <div className="row">
            {
              (this.context.request.type === this.AMENDMENT_WAIVER || this.context.request.type === this.DEAL_ANNUAL_REVIEW)
              && this.displayDetailsForAWOrDealAnnualReview()
            }
            {
              this.context.request.type !== this.AMENDMENT_WAIVER && this.context.request.type !== this.DEAL_ANNUAL_REVIEW
              && this.displayDetailsForCommonTypes()
            }
            {
              this.context.requestType === RequestType.FINANCING_LIMIT && this.props.ceilingDecision
              && this.displayDetailsForCeiling()
            }
          </div>
        </div>
      </div>
    )
  }

  private displayDetailsForCommonTypes() {
    return (
      <div className="col-12">
        <div className="text-secondary font-weight-medium mb-1">Facilities/Limits</div>
        {
          !!this.props.decisionTypesCounter.get(DecisionType.APPROVAL) &&
          <div className="mb-2">
            <span className="badge badge-pill badge-success mr-2">{this.props.decisionTypesCounter.get(DecisionType.APPROVAL)}</span>Approved
          </div>
        }
        {
          !!this.props.decisionTypesCounter.get(DecisionType.APPROVAL_WITH_MODIFICATION) &&
          <div className="mb-2">
                  <span
                    className="badge badge-pill badge-warning mr-2">{this.props.decisionTypesCounter.get(DecisionType.APPROVAL_WITH_MODIFICATION)}</span>Approved
            w/ modifications</div>
        }
        {
          !!this.props.decisionTypesCounter.get(DecisionType.REFUSAL) &&
          <div className="mb-2">
            <span className="badge badge-pill badge-danger mr-2">{this.props.decisionTypesCounter.get(DecisionType.REFUSAL)}</span>Refused
          </div>
        }
        {
          !!this.props.decisionTypesCounter.get(DecisionType.RETURN_TO_STUDY) &&
          <div className="mb-2"><span
            className="badge badge-pill badge-success mr-2">{this.props.decisionTypesCounter.get(DecisionType.RETURN_TO_STUDY)}</span>Return to study
          </div>
        }
      </div>
    )
  }

  private displayDetailsForCeiling() {
    return (
      <div className="col-12">
        <div className="text-secondary font-weight-medium mb-1">Ceiling</div>
      {this.props.ceilingDecision &&
        <div className="mb-2" data-testid="ceiling-status">
          <span className={this.populateCeilingDisplayCss(this.props.ceilingDecision.type)}>1</span>{this.populateStatus(this.props.ceilingDecision.type)}
        </div>
      }
      </div>
    )
  }

  private populateStatus(type: DecisionType): string {
      switch (type) {
        case DecisionType.APPROVAL_WITH_MODIFICATION:
          return "Approved w/ modifications";
        case DecisionType.REFUSAL:
          return "Refused";
        case DecisionType.RETURN_TO_STUDY:
          return "Return to study";
        default :
          return "Approved";
      }
  }

  private populateCeilingDisplayCss(type : DecisionType): string {
      switch (type) {
        case DecisionType.APPROVAL_WITH_MODIFICATION:
          return "badge badge-pill badge-warning mr-2";
        case DecisionType.REFUSAL:
          return "badge badge-pill badge-danger mr-2";
        default:
          return "badge badge-pill badge-success mr-2";
      }
  }

  private displayDetailsForAWOrDealAnnualReview() {
    return (
      <div className="col-12">
        {this.context.request.type === this.AMENDMENT_WAIVER && <div className="text-secondary font-weight-medium mb-1">Decision on Waiver Amendment</div>}
        {this.context.request.type === this.DEAL_ANNUAL_REVIEW && <div className="text-secondary font-weight-medium mb-1">Decision on Deal Annual Review</div>}
        {
          !!this.props.decisionTypesCounter.get(DecisionType.APPROVAL) &&
          <div className="mb-2 text-success">Approved</div>
        }
        {
          !!this.props.decisionTypesCounter.get(DecisionType.APPROVAL_WITH_MODIFICATION) &&
          <div className="mb-2 text-warning">Approved w/ modifications</div>
        }
        {
          !!this.props.decisionTypesCounter.get(DecisionType.REFUSAL) &&
          <div className="mb-2 text-danger">Refused</div>
        }
        {
          !!this.props.decisionTypesCounter.get(DecisionType.RETURN_TO_STUDY) &&
          <div className="mb-2 text-success">Return to study</div>
        }
      </div>
    )
  }
}
