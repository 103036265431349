import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';
import { piwik } from '../../../utils/tracking/piwik.service';
import { removeLastSlash } from '../../../utils/util.service';
import DealInfoComponent from '../DealInfo/DealInfoComponent';
import { DecisionComponent } from '../Decision/DecisionComponent';
import { DecisionSuccessComponent } from '../Decision/DecisionSuccess/DecisionSuccessComponent';
import AccessDeniedComponent from '../ErrorPages/AccessDenied/AccessDeniedComponent';
import DecisionFailureComponent from '../ErrorPages/DecisionFailure/DecisionFailureComponent';
import { InternalErrorComponent } from '../ErrorPages/InternalError/InternalErrorComponent';
import PageNotFoundComponent from '../ErrorPages/PageNotFound/PageNotFoundComponent';
import { UnavailableErrorComponent } from '../ErrorPages/UnavailableError/UnavailableErrorComponent';
import { ErrorType } from './ErrorType';
import history from '../../../context/History';
import { HomePageComponent } from '../HomePage/HomePageComponent';
import { RouteTracker } from './RouteTracker';
import { ScrollToTop } from './ScrollToTop';
import { withLoadingComponent } from './WithLoadingComponent';

const appHistory = piwik ? piwik.connectToHistory(history) : history;

interface IProp {
  refreshGlobalContext: () => void
}

class RouterComponent extends React.Component<IProp> {

  render(): React.ReactNode {
    return (
      <Router history={appHistory}>
        <ScrollToTop>
          <RouteTracker>
            <Switch>
              <Route exact path='/:requestCode(\d{4}_\d{2}_\d{6})' component={DealInfoComponent}/>
              <Route exact path='/:requestCode(\d{4}_\d{2}_\d{6})/decision' component={DecisionComponent}/>
              <Route exact path='/:requestCode(\d{4}_\d{2}_\d{6})/decision/success'>
                <DecisionSuccessComponent returnToDealInfoPage={() => this.navigateAndRefreshGlobalContext('/decision/success')}/>
              </Route>
              <Route exact path='/:requestCode(\d{4}_\d{2}_\d{6})/decision/failure'>
                <DecisionFailureComponent returnToDecisionPage={() => this.navigateAndRefreshGlobalContext('/failure')}/>
              </Route>
              <Route exact path='/' component={HomePageComponent}/>
              <Route path='*/access-denied' component={AccessDeniedComponent}/>
              <Route path='*/not-found' component={PageNotFoundComponent}/>
              <Route path='*/internal-error'>
                <InternalErrorComponent returnToDealInfoPage={() => this.navigateAndRefreshGlobalContext(ErrorType.INTERNAL_ERROR)}/>
              </Route>
              <Route path='*/unavailable-service'>
                <UnavailableErrorComponent returnToDealInfoPage={() => this.navigateAndRefreshGlobalContext(ErrorType.UNAVAILABLE_SERVICE)}/>
              </Route>
              <Redirect to={removeLastSlash(history.location.pathname) + ErrorType.NOT_FOUND}/>
            </Switch>
          </RouteTracker>
        </ScrollToTop>
      </Router>
    );
  }

  private async navigateAndRefreshGlobalContext(urlPathToRemove: string) {
    const url = history.location.pathname;
    history.replace(url.slice(0, url.lastIndexOf(urlPathToRemove)));
    this.props.refreshGlobalContext()
  }
}

export default withLoadingComponent(RouterComponent);
