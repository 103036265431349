import * as React from 'react';
import { IMarketRequestStatus } from '../../../../../domain/models/market-request-model';
import { formatDateToString } from '../../../../../utils/conversion.service';

interface IProps {
  marketRequestStatus: IMarketRequestStatus;
}

enum TypeBadge {
  SECONDARY = 'badge-discreet-secondary',
  WARNING = 'badge-discreet-warning',
  INFO = 'badge-discreet-info',
  SUCCESS = 'badge-discreet-success',
  DANGER = 'badge-discreet-danger'
}

export const BADGE_SEPARATOR = '·';

export class MarketRequestStatusBadgeComponent extends React.Component<IProps> {

  render() {
    return (<div className="text-large my-2">{factoryBadge(this.props.marketRequestStatus)}</div>)
  }

}

function factoryBadge({status, date}: IMarketRequestStatus) {
  const dateFormatted = date ? formatDateToString(date) : undefined;
  const displayWarningIcon = date && date.getTime() < (new Date()).getTime();
  switch (status) {
    case 'ABORTED':
      return renderBadge('Aborted', TypeBadge.SECONDARY);
    case 'PROCESSED_IN_CREDIT_WORKFLOW':
      return renderBadge('Processed in Credit Workflow', TypeBadge.WARNING);
    case 'IN_PROGRESS':
      return renderBadge(`In Progress ${BADGE_SEPARATOR} Deadline on ${dateFormatted}`, TypeBadge.INFO, displayWarningIcon);
    case 'RETURN_TO_STUDY':
      return renderBadge('Returned to Study', TypeBadge.WARNING);
    case 'FINALLY_APPROVED':
      return renderBadge('Approved', TypeBadge.SUCCESS);
    case 'FINALLY_APPROVED_WITH_MODIFICATION':
      return renderBadge('Approved with Modifications', TypeBadge.WARNING);
    case 'FINALLY_PARTIALLY_APPROVED':
      return renderBadge('Partially Approved', TypeBadge.WARNING);
    case 'FINALLY_REFUSED':
      return renderBadge('Refused', TypeBadge.DANGER);
    case 'DRAFT':
      return renderBadge(`Draft ${BADGE_SEPARATOR} Not Submitted`, TypeBadge.SECONDARY);
  }
}

function renderBadge(text: string, typeBadge: TypeBadge, displayWarningIcon?: boolean) {
  return <div data-testid="badge-request-status" className={`badge badge-pill ${typeBadge}`}>
    <span>{text}</span>
    {displayWarningIcon && <i data-testid="warning-icon" className="icon icon-sm ml-1">warning</i>}
  </div>;
}

