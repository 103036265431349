import { IMarketRequest, IMarketRequestStatus } from '../models/market-request-model';

export function getMarketRequestStatus({
                                         workflowStatus,
                                         containsMultiBorrowerAuthorization,
                                         dependsOnMissingAuthorizations,
                                         expectedApprovalDate
                                       }: IMarketRequest): IMarketRequestStatus {
  const {status} = workflowStatus;
  switch (status) {
    case 'ABORTED':
      return {
        status: containsMultiBorrowerAuthorization || dependsOnMissingAuthorizations ? 'PROCESSED_IN_CREDIT_WORKFLOW' : status
      };
    case 'IN_PROGRESS':
      return {
        status,
        date: new Date(expectedApprovalDate)
      };
    case 'RETURN_TO_STUDY':
    case 'FINALLY_APPROVED':
    case 'FINALLY_APPROVED_WITH_MODIFICATION':
    case 'FINALLY_PARTIALLY_APPROVED':
    case 'FINALLY_REFUSED':
    case 'DRAFT':
      return {
        status
      };
    default:
      throw Error('Unable to build market request status');
  }
}
