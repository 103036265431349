import { AxiosRequestConfig } from "axios";

export function getConf(): AxiosRequestConfig {
  let conf: AxiosRequestConfig = {};
  const widget = document.querySelector("sgwt-connect") as any;
  if (widget.sgwtConnect) {
    conf = {
      headers: {
        "Authorization": widget.sgwtConnect.getAuthorizationHeader(),
      }
    };
  }
  return conf;
}
