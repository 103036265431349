import * as React from 'react';
import { IRequestVersionHistory } from '../../../../../domain/models/financing-request-model';
import { IMarketRequest } from '../../../../../domain/models/market-request-model';
import { requestResource } from '../../../../../domain/resources/request.resource';
import { getMarketRequestStatus } from '../../../../../domain/services/market-request-status.service';
import { withLoadingComponent } from '../../../common/WithLoadingComponent';
import { MarketRequestStatusBadgeComponent } from '../MarketRequestStatusBadge/MarketRequestStatusBadgeComponent';

interface IState {
  loaded: boolean;
  type?: string;
}

interface IProps {
  request: IMarketRequest
}

export default class MarketDealHeaderComponent extends React.Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      type: undefined,
    }
  }

  async componentDidMount() {
    const requestVersionDetails = await requestResource.getMarketRequestVersionDetails(this.props.request.code);

    const {type} = requestVersionDetails.requestVersions.reduce(
      (a: IRequestVersionHistory, b: IRequestVersionHistory) => a.requestVersion < b.requestVersion ? b : a);
    this.setState({loaded: true, type});
  }

  render() {
    const DealHeaderWithLoading = withLoadingComponent(() => this.getDealHeaderData());
    return <DealHeaderWithLoading loaded={this.state.loaded}/>
  }

  private getDealHeaderData() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="text-large text-secondary font-weight-medium mt-4 mb-3">Market credit file</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="display-4 my-0">{this.props.request.name}</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="text-xlarge my-0">{toFirstCapitalLetter(this.state.type!!)} · {getTypologyOfRisk(this.props.request)}</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <MarketRequestStatusBadgeComponent marketRequestStatus={getMarketRequestStatus(this.props.request)}/>
          </div>
        </div>

      </div>
    );
  }
}

const getTypologyOfRisk = ({marketLines}: IMarketRequest) =>
  marketLines.map(marketLine => marketLine.typologyOfRiskLabel).join(', ');

const toFirstCapitalLetter = (type: string) =>
  type.toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
