import { post } from '../../utils/axios/api';
import { ITicket } from '../models/ITicket';

const DOMAIN = (process.env.NODE_ENV === 'development') ? 'https://cav-hom-impulse-back.fr.world.socgen' : '/impulse-back';
export const BASE_URL = 'api/v1/tickets';

export class ImpulseResource {

  domain: string;

  constructor(domain = DOMAIN) {
    this.domain = domain
  }

  create = async (ticket: ITicket): Promise<any> => {
    return post(`${this.domain}/${BASE_URL}`, ticket);
  }
}

export const impulseResource = new ImpulseResource();
