import React from 'react';
import { GlobalContext } from '../../../../context/global.context';
import {totalCeiling, totalLines} from '../../../../domain/services/request.service';
import { formatDateToString } from '../../../../utils/conversion.service';

// TODO("financingLines + marketLines")
export class OriginatedRequestComponent extends React.Component {

  static contextType = GlobalContext;
  private AMENDMENT_WAIVER = 'AMENDMENT/WAIVER';
  private DEAL_ANNUAL_REVIEW = 'DEAL_ANNUAL_REVIEW';

  render() {

    return (
      <div data-testid="OriginatedRequestComponent">
        <div className="row">
          <div className="col">
            <h3 className="mb-1 mt-4">1. Check the request</h3>
          </div>
        </div>

        <div className="card card-bordered my-2 px-3 pt-3 pb-2 text-large">
          <div className="row mb-4">
            <div className="col">
              <div className="text-secondary font-weight-medium">Initiator</div>
              <div>{this.context.initiator.fullName} ({this.context.initiator.teamLabel})<br/> {formatDateToString(this.context.request.createdOn)}</div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              {
                (this.context.request.type === this.AMENDMENT_WAIVER || this.context.request.type === this.DEAL_ANNUAL_REVIEW)
                && this.displayDetailsForAWOrDealAnnualReview()
              }
              {
                this.context.request.type !== this.AMENDMENT_WAIVER && this.context.request.type !== this.DEAL_ANNUAL_REVIEW
                && this.displayDetailsForCommonTypes()
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  private displayDetailsForCommonTypes() {
    return (
      <div>
        <div className="text-secondary font-weight-medium">Facilities/Limits</div>
        <div className="mb-2">
                <span
                  className="badge badge-pill badge-success mr-2">{totalLines(this.context.request)}</span>Approved
        </div>
        {this.context.request.ceiling !== null && <div className="text-secondary font-weight-medium">Ceiling</div> }
        {this.context.request.ceiling !== null && <div className="mb-2">
                <span
                  className="badge badge-pill badge-success mr-2">{totalCeiling(this.context.request)}</span>Approved
        </div> }
      </div>
    )
  }

  private displayDetailsForAWOrDealAnnualReview() {
    return (
      <div className="mb-2">
        {this.context.request.type === this.AMENDMENT_WAIVER && <div className="text-secondary font-weight-medium mb-1">Decision on Waiver Amendment</div>}
        {this.context.request.type === this.DEAL_ANNUAL_REVIEW && <div className="text-secondary font-weight-medium mb-1">Decision on Deal Annual Review</div>}
        <span className="text-success">Approved</span>
      </div>
    )
  }
}
