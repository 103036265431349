import { BatchLogger } from 'web-tracker-library';
import { getConf } from '../axios/axios-conf';
import { metricsAxiosInstance } from '../axios/tracking-axios';


const DOMAIN = (process.env.NODE_ENV === 'development') ? 'https://cav-hom-ui-metrics-back.fr.world.socgen' : '/ui-metrics-back';

export class MetricsLogger implements BatchLogger {

  domain: string;

  constructor(domain = DOMAIN) {
    this.domain = domain
  }

  log(event: any[]): void {
    metricsAxiosInstance.post(this.domain + '/api/v1/metrics', event, getConf())
  }
}

export const metricsLogger = new MetricsLogger();
