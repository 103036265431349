import '@sg-bootstrap/core/build/sg-bootstrap-standard.css';
// Load SG Bootstrap v4 CSS
import '@sg-bootstrap/icons/dist/index.css';
// Polyfills
import 'core-js/es6/array';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './components/App/App';
import { Callback, networkRedirection } from './domain/services/network-redirection.service';

// Auto-login with SG Connect, using the <sgwt-connect> widget
const widget = document.querySelector('sgwt-connect') as any;

const login = async () => {
  const sgwtConnect = widget.sgwtConnect;
  widget.removeEventListener('sgwt-connect--ready', login);
  console.debug('Here are the env variables', process.env);
  if (sgwtConnect.getAuthorizationError()) {
    // Case #1: an error occurred during the retrieval of the SG Connect token...
    document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        Authorization error: ${sgwtConnect.getAuthorizationError()}.
      </div>`;
  } else if (sgwtConnect.isAuthorized()) {
    const isRedirecting = await networkRedirection(window.location.href, (callback: Callback) => widget.sgwtConnect.fetchUserInfo(callback), process.env.REACT_APP_MYCREDIT_FRONT_URL as string);
    // Case #2: the user is authenticated, we load the application
    if (!isRedirecting) {
      ReactDOM.render(<App/>, document.getElementById('app-root'));
    }
  } else {
    // Case #3: the user is not authenticated, we request an authorization...
    sgwtConnect.requestAuthorization();
  }
}

if (widget.sgwtConnect) {
  login();
} else {
  widget.addEventListener('sgwt-connect--ready', login);
}
