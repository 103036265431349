import { IAggregateAmount } from 'line-aggregator/dist/src/models/IAggregateAmount';
import IAggregateTenor from 'line-aggregator/dist/src/models/IAggregateTenor';
import IAmount from 'line-aggregator/dist/src/models/IAmount';
import ITenor from 'line-aggregator/dist/src/models/ITenor';
import * as React from 'react';
import { GlobalContext, IGlobalContext } from '../../../../../context/global.context';
import { IDealTeamRiskSharing, IFinancingRequest } from '../../../../../domain/models/financing-request-model';
import { getLastDecision } from '../../../../../domain/services/decision.service';
import financingLineAggregator from '../../../../../utils/aggregator/FinancingLineAggregator';
import { abbreviateNumber } from '../../../../../utils/conversion.service';

interface IState {
  sgFinalTakeAmount?: IAggregateAmount,
  maxTenor?: IAggregateTenor | string,
  errorMessage?: string
}

export default class FinancingRequestSummaryComponent extends React.Component<{}, IState> {

  static contextType: React.Context<IGlobalContext> = GlobalContext;

  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const {request} = this.context as IGlobalContext;
    try {
      if (request.type !== 'AMENDMENT/WAIVER' && request.type !== 'DEAL_ANNUAL_REVIEW') {
        const lastDecision = await getLastDecision(request.code);
        const financingLineDecisions = lastDecision ? lastDecision.financingLineDecisions : undefined;
        const {financingLines, dealCurrency} = request as IFinancingRequest;
        const [sgFinalTakeAmount, maxTenor] = await Promise.all(
          [financingLineAggregator.getFinalTakeTotalAmount(financingLines, dealCurrency, financingLineDecisions),
           financingLineAggregator.getMaxTenor(financingLines, financingLineDecisions)]);
        this.setState({sgFinalTakeAmount, maxTenor});
      }
      else {
        const {finalTake, dealCurrency, dealMaturity} = request as IFinancingRequest;
        const sgFinalTakeAmount = {initial: {currency: dealCurrency, value: finalTake}};
        this.setState({sgFinalTakeAmount, maxTenor: dealMaturity});
      }

    } catch (error) {
      console.error('Error loading last decision and exchange rates', error);
      this.setState({...this.state, errorMessage: 'Not able to compute this value.'});
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <h3 className="mb-1 mt-4">Request summary</h3>
          </div>
        </div>

        <div className="card card-bordered my-2 p-3">
          {this.state.errorMessage
           || <>
             <div className="row mb-4">
               <div className="col-6">
                 <div className="text-large text-secondary font-weight-medium">SG final take</div>
                 {getSgFinalTakeAmountRender(this.state)}
               </div>
               <div className="col-6">
                 <div className="text-large text-secondary font-weight-medium">Max tenor</div>
                 {getMaxTenorRender(this.state)}
               </div>
             </div>
             <div className="row">
               <div className="col-12">
                 <div className="text-large text-secondary font-weight-medium">Risk sharing</div>
                 {
                   this.context.request.dealTeamRiskSharings.map((dealTeamRiskSharing: IDealTeamRiskSharing) =>
                                                                   <div key={dealTeamRiskSharing.businessLineName} className="text-large">
                                                                     {dealTeamRiskSharing.businessLineName} ({dealTeamRiskSharing.businessLinePercentage} %)
                                                                   </div>
                   )
                 }
               </div>
             </div>
           </>
          }
        </div>
      </div>
    )
  }
}

const getSgFinalTakeAmountRender = ({sgFinalTakeAmount}: IState) => {
  if (!sgFinalTakeAmount) {
    return <div data-testid="SgFinalTake" className="text-large">-</div>
  }
  if (sgFinalTakeAmount.modified && amountAreNotEquals(sgFinalTakeAmount.modified, sgFinalTakeAmount.initial)) {
    return <div data-testid="SgFinalTake" className="text-large text-info">
      {sgFinalTakeAmount.modified.currency} {abbreviateNumber(sgFinalTakeAmount.modified.value)}
    </div>
  }
  return <div data-testid="SgFinalTake" className="text-large">
    {sgFinalTakeAmount.initial.currency} {abbreviateNumber(sgFinalTakeAmount.initial.value)}
  </div>
};

const getMaxTenorRender = ({maxTenor}: IState) => {

  if (!maxTenor) {
    return <div data-testid="MaxTenor" className="text-large">-</div>
  }

  if (maxTenor && typeof maxTenor === 'string') {
    return <div data-testid="MaxTenor" className="text-large">
      {maxTenor}
    </div>
  }
  else {
    const tenor = maxTenor as IAggregateTenor
    if (tenor.modified && tenorsAreNotEquals(tenor.modified, tenor.initial)) {
      return <div data-testid="MaxTenor" className="text-large text-info">
        {formatTenorToString(tenor.modified)}
      </div>
    }
    return <div data-testid="MaxTenor" className="text-large">
      {formatTenorToString(tenor.initial)}
    </div>
  }
};

const amountAreNotEquals = (amount1: IAmount, amount2: IAmount) =>
  amount1.currency !== amount2.currency
  || amount1.value !== amount2.value;

const tenorsAreNotEquals = (tenor1: ITenor, tenor2: ITenor) =>
  tenor1.years !== tenor2.years
  || tenor1.months !== tenor2.months
  || tenor1.days !== tenor2.days;

const formatTenorToString = ({years, months, days}: ITenor) => `${years}Y ${months}M ${days}D`;
