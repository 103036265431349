import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';

import { IPropsWithGlobalContext, withGlobalContext } from '../../../context/global.context';
import { AccountCenter } from './AccountCenter';

export const Header = withGlobalContext(
  class extends React.Component<IPropsWithGlobalContext, {}> {
    render() {
      const logoSg = require('../../../assets/images/logo_sg.svg');
      const logoSgm = require('../../../assets/images/logo_sgm.svg');

      return (
        <Navbar
          light={true}
          expand='xl'
          className='bg-white d-flex justify-content-between align-content-center px-0'>
          <div className="container px-4">
            <div className="d-flex">
              <NavbarBrand href='/'>
                <img src={logoSg} height="32" width="32" alt='SG Markets' className='d-sm-block'/>
              </NavbarBrand>
            </div>

            <div className="d-flex align-items-center order-xl-1">
              <AccountCenter />
              <svg className="d-none d-md-block overflow-visible ml-3" height="32" width="145">
                <image xlinkHref={logoSgm} height="16" width="145" x="0" y="0"/>
                <text className="font-family-display" height="16" width="145" x="0" y="32">My Credit Mobile Approver</text>
              </svg>
            </div>
          </div>
        </Navbar>
      );
    }
  }
);
