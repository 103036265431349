import React from 'react';
import { Delegation } from '../../../../../domain/models/delegation';

interface IProps {
  handleCreditChoice: (delegation: Delegation) => void;
  delegation: Delegation;
}

export class CreditAuthorityComponent extends React.Component<IProps> {

  render() {
    return (
      <div data-testid="CreditAuthorityComponent" className="mb-4">
        <div className="row">
          <div className="col">
            <div className="text-xlarge text-secondary mb-2">Do you want to engage your credit authority ?</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="btn-group btn-block btn-group-toggle my-2">
              <button data-testid="creditAuthorityYes"
                      data-tracking-tag="mobile-credit-yes"
                      className={this.getButtonClass(Delegation.CREDIT_AUTHORITY)}
                      onClick={() => this.props.handleCreditChoice(Delegation.CREDIT_AUTHORITY)}>Yes
              </button>
              <button data-testid="creditAuthorityNo"
                      data-tracking-tag="mobile-credit-no"
                      className={this.getButtonClass(Delegation.RECOMMENDATION)}
                      onClick={() => this.props.handleCreditChoice(Delegation.RECOMMENDATION)}>No
              </button>
            </div>
          </div>
        </div>

        {
          this.props.delegation === Delegation.RECOMMENDATION &&
          <div className="alert alert-outline-warning text-large text-warning mt-2 mb-3">
            <span className="font-weight-bold">You have to use the desktop version of MyCredit to make this decision</span>
            <br/>
            It's currently not possible to make a decision in MyCredit Mobile Approver if you don't want to engage your credit authority.
          </div>
        }
      </div>
    )
  }

  private getButtonClass(delegation: Delegation) {
    return 'btn btn-xl btn-toggle-primary ' + (this.props.delegation === delegation ? 'active' : '');
  }
}
