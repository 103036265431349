import React from 'react';
import { GlobalContext } from '../../../context/global.context';
import { emptyLatestDecision, ILatestDecision } from '../../../domain/models/latest-decision-model';
import { IPotentialDecisionMaker } from '../../../domain/models/potential-decision-maker';
import { emptyUserBasicInfo, IUserBasicInfo } from '../../../domain/models/user';
import { workflowResource } from '../../../domain/resources/workflow.resource';
import { redirectToErrorPage } from '../../../utils/util.service';
import { ErrorType } from '../common/ErrorType';
import history from '../../../context/History';
import { withLoadingComponent } from '../common/WithLoadingComponent';
import { LastDecisionDetailsComponent } from './LastDecisionDetails/LastDecisionDetailsComponent';
import { MakeDecisionComponent } from './MakeDecision/MakeDecisionComponent';
import { OriginatedRequestComponent } from './OriginatedRequest/OriginatedRequestComponent';
import {ICeilingDecisionCommand} from "../../../domain/models/financing-limit-request.models";
import {decisionResource} from "../../../domain/resources/decision.resource";

interface IState {
  loaded: boolean;
  latestDecision: ILatestDecision;
  initiator: IUserBasicInfo;
  decisionTypesCounter: Map<string, number>;
  potentialDecisionMakers: IPotentialDecisionMaker[];
  ceilingDecision ?: ICeilingDecisionCommand;
}

export class DecisionComponent extends React.Component<{}, IState> {

  static contextType = GlobalContext;

  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      latestDecision: emptyLatestDecision,
      initiator: emptyUserBasicInfo,
      decisionTypesCounter: new Map(),
      potentialDecisionMakers: [],
    }
  }

  async componentDidMount() {
    const workflowId = this.context.request.workflowId;
    const requestId = this.context.request.code;
    const [latestDecision, decisionTypesCounter, potentialDecisionMakers, ceilingDecision] = await Promise.all([
                                                                                                decisionResource.getLatestDecision(requestId),
                                                                                                decisionResource.getApprovableQuantitiesByDecisionTypeForLatestDecision(requestId),
                                                                                                workflowResource.getPotentialDecisionMakers(workflowId),
                                                                                                decisionResource.getCeilingDecisionHistory(requestId)
                                                                                              ]);

    if (potentialDecisionMakers.length === 0) {
      redirectToErrorPage(ErrorType.ACCESS_DENIED);
    } else {
      this.setState({latestDecision, decisionTypesCounter: new Map(Object.entries(decisionTypesCounter)), potentialDecisionMakers, loaded: true,ceilingDecision});
    }
  }

  render() {
    const DecisionDataWithLoading = withLoadingComponent(() => this.decisionRenderer());
    return <DecisionDataWithLoading loaded={this.state.loaded}/>
  }

  private decisionRenderer() {
    return (
      <div data-testid="DecisionComponent">
        <div className="container px-4">
          <div className="row">
            <div className="col">
              <div data-testid="goBackButtonHeader"
                   data-tracking-tag="mobile-back-credfile"
                   className="text-large text-secondary font-weight-medium mt-4 mb-3 btn-link"
                   onClick={goBackToDealInfo}>
                <span className="icon icon-sm">arrow_back</span> Back to credit file
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="display-4 mt-0 mb-4">Make your decision</div>
            </div>
          </div>
        </div>

        <div className="border-bottom"/>

        <div className="container px-4">
          {
            !!this.state.latestDecision.requestDecisionId ?
            <LastDecisionDetailsComponent latestDecision={this.state.latestDecision}
                                          decisionTypesCounter={this.state.decisionTypesCounter}
                                          ceilingDecision={this.state.ceilingDecision}/> :
            <OriginatedRequestComponent/>
          }
          <MakeDecisionComponent latestDecision={this.state.latestDecision} potentialDecisionMakers={this.state.potentialDecisionMakers}/>

          <div className="row">
            <div className="col">
              <div data-testid="goBackButtonBottom"
                   data-tracking-tag="mobile-back-credfile"
                   className="btn btn-block btn-xl sgbs-btn-default mt-3 mb-2"
                   onClick={goBackToDealInfo}>
                Go back to credit file
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }

}

function goBackToDealInfo() {
  history.goBack();
}
