import React from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'sgwt-help-center': any;
    }
  }
}

export const HelpCenter: React.FC = () => {
  const mail = btoa(process.env.REACT_APP_CONTACT_MAIL!);
  return (
    <aside>
      <sgwt-help-center id="my-help-center" sg-connect-support='sg-connect-v2' default-send-to={mail} application-id='mycredit-mobile-approver-front'/>
    </aside>
  );
};
