import React from "react";

interface IProps {
  returnToDealInfoPage: () => void;
}

export class DecisionSuccessComponent extends React.Component<IProps> {

  render() {
    return (
      <div data-testid="DecisionSuccessComponent" className="container px-4">
        <div className="row">
          <div className="d-flex col">
            <div className="rounded-circle bg-success-alpha-md d-flex mt-5 mb-3 align-items-center justify-content-center"
                 style={{width: "3rem", height: "3rem"}}>
              <span className="icon icon-md text-success">check</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2 className="text-success mb-3">Your decision has been submitted</h2>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="text-large text-secondary mb-3">You can now leave the application or go back to the credit file if you want to review the details.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div data-testid="goBackToCreditFile" className="btn btn-block btn-xl sgbs-btn-default mt-3 mb-4" onClick={() => this.goBackToDealInfoPage()}>
              Go back to credit file
            </div>
          </div>
        </div>

      </div>
    );
  }

  private goBackToDealInfoPage() {
    this.props.returnToDealInfoPage();
  }
}
