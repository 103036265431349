import React from "react";
import { GlobalContext } from "../../../context/global.context";
import { workflowResource } from '../../../domain/resources/workflow.resource';
import DealHeaderComponent from "./DealHeader/DealHeaderComponent";
import MakeDecisionButton from "./MakeDecisionButton/MakeDecisionButton";
import OverviewComponent from "./Overview/OverviewComponent";

interface IState {
  hasDecisionRight: boolean;
}

export default class DealInfoComponent extends React.Component<{}, IState> {

  static contextType = GlobalContext;

  constructor(props: any) {
    super(props);
    this.state = {
      hasDecisionRight: false,
    };
  }

  async componentDidMount() {
    const hasDecisionRight: boolean = await workflowResource.canGiveDecision(this.context.request.workflowId);
    this.setState({hasDecisionRight});
  }

  render() {
    return (
      <div className="container px-4">
        <DealHeaderComponent/>
        {this.state.hasDecisionRight && <MakeDecisionButton/>}
        <OverviewComponent/>
        {this.state.hasDecisionRight && <MakeDecisionButton/>}
      </div>
    );
  }
}
