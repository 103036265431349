import { IFinancingLine } from './IFinancingLine';
import { IMarketLine } from './market-request-model';
import { IRequest, ObligorType } from './request-model';
import { RequestType } from './request-type';

export interface IFinancingRequest extends IRequest {
  requestVersion: number;
  workflowId: string;
  dealCurrency: string;
  dealTeamRiskSharings: IDealTeamRiskSharing[];
  risqApprovalValidity: IDuration;
  actualSigning?: string;
  abandonDate?: string;
  dealStatus?: 'Abandoned';
  requestStatus?: IRequestStatus;
  finalApprovalDeadline?: string;
  financingLines: IFinancingLine[];
  marketLines: IMarketLine[];
  dealMaturity: string;
  finalTake: number;
}

export interface IFinancingRequestStatus {
  status: 'RECALLED'
    | 'IN_PROGRESS'
    | 'ABANDONED'
    | 'RETURN_TO_STUDY'
    | 'FINALLY_APPROVED'
    | 'FINALLY_APPROVED_WITH_MODIFICATION'
    | 'FINALLY_PARTIALLY_APPROVED'
    | 'FINALLY_REFUSED'
    | 'DRAFT';
  date?: Date;
  isSigning?: boolean;
  deadLine?: Date
}

export interface IRequestVersionHistory {
  requestVersion: number;
  type: string;
  description: string;
  regularized: boolean;
}

export interface IRequestVersionsDTO {
  requestVersions: IRequestVersionHistory[];
}

export interface IDealTeamRiskSharing {
  businessLineName: string;
  businessLinePercentage: string;
}

export interface IDuration {
  [key: string]: number;

  years: number;
  months: number;
  days: number;
}

export enum IRequestStatus {
  RECALLED = 'RECALLED'
}

export interface IRequestSummary {
  sourceType: RequestType;
  type ?: string;
}

export const defaultFinancingRequest: IFinancingRequest = {
  code: '',
  financingLines: [],
  marketLines: [],
  requestVersion: 0,
  createdOn: new Date(),
  initiatorUserId: '',
  obligor: {
    type: ObligorType.MAIN_COMPANY,
  },
  workflowId: '',
  name: '',
  dealCurrency: '',
  dealTeamRiskSharings: [],
  workflowStatus: {
    status: 'DRAFT',
    date: new Date()
  },
  risqApprovalValidity: {
    years: 0,
    months: 0,
    days: 0
  },
  type: 'NEW',
  dealMaturity: '0Y 0M 0D',
  finalTake: 0
};
