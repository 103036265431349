import { addMonths, isAfter } from 'date-fns';
import * as React from 'react';
import { GlobalContext } from '../../../../../context/global.context';
import { IObligor, ObligorType } from '../../../../../domain/models/request-model';

export default class ObligorComponent extends React.Component {

  static contextType = GlobalContext;

  render() {
    const company = getCompany(this.context.request.obligor);
    const mainCompany = getMainCompany(this.context.request.obligor);

    return (
      <div>
        <div className="row">
          <div className="col">
            <h3 className="mb-1 mt-4">Obligor</h3>
          </div>
        </div>

        <div className="card card-bordered my-2 p-3">
          <div className="row mb-4">
            <h4 className="col-12 mb-0 mt-0">{company.name}</h4>
            {
              company.onWatchList &&
              <div className="col-12">
                <div className="badge badge-discreet-danger badge-prepend-square text-large mt-1">Watch list</div>
              </div>
            }
          </div>
          <div className="row mb-4">
            <div className="col-6">
              <div className="text-large text-secondary font-weight-medium">Rating FC/LC</div>
              {
                !!company.ratings.foreignAndLocalCurrencies && !!company.ratings.foreignAndLocalCurrencies.value ?
                <div data-testid="obligorRating"
                     className={ratingClass(company.ratings.foreignAndLocalCurrencies.validity!!)}>{company.ratings.foreignAndLocalCurrencies.value}</div> :
                <div className="text-xlarge">-/-</div>
              }
            </div>
            <div className="col-6">
              <div className="text-large text-secondary font-weight-medium">LOD1 proposal</div>
              {
                !!company.lodProposal && !!company.lodProposal.value ?
                <div className="text-xlarge">{company.lodProposal.value}</div> :
                <div className="text-xlarge">-</div>
              }
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <div className="text-large text-secondary font-weight-medium">PCRU</div>
              {
                !!company.pcruName ?
                <div className="text-large">{company.pcruName}</div> :
                <div className="text-large">-</div>
              }
            </div>
          </div>
          {
            !!mainCompany &&
            <div className="row">
              <div className="col-12">
                <div className="text-large text-secondary font-weight-medium">Main company</div>
                <div className="text-large">{mainCompany.name}</div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

}

function ratingClass(validity: Date): string {
  const today = new Date(Date.now());
  const expirationDate = new Date(validity);
  if (isAfter(today, expirationDate)) {
    return 'text-xlarge text-danger';
  } else if (isAfter(addMonths(today, 3), expirationDate)) {
    return 'text-xlarge text-warning';
  } else {
    return 'text-xlarge text-success';
  }
}

function getCompany(obligor: IObligor) {
  if (obligor.type === ObligorType.MAIN_COMPANY) {
    return obligor.parentCompany!!;
  }
  return obligor.company!!;
}

function getMainCompany(obligor: IObligor) {
  return obligor.parentCompany!!;
}
