import { IntlSGWTProvider } from '@sgwt/intl-react';
import React from 'react';
import { defaultContext, GlobalContext, IGlobalContext } from '../../context/global.context';
import { ITicket } from '../../domain/models/ITicket';
import { userResource } from '../../domain/resources/user.resource';
import { hasAccess } from '../../domain/services/access.service';
import { loadGlobalContext } from '../../domain/services/global-context.service';
import { create } from '../../domain/services/impulse.service';
import webTrackerService from '../../utils/tracking/WebTrackerService';
import { isRequestCodeValid, redirectToErrorPage } from '../../utils/util.service';
import './App.css';
import { ErrorType } from './common/ErrorType';
import { Header } from './common/Header';
import { HelpCenter } from './common/HelpCenter';
import history from '../../context/History';
import { MiniFooter } from './common/MiniFooter';
import RouterComponent from './common/RouterComponent';
import { DesktopPageComponent } from './ErrorPages/DesktopPage/DesktopPageComponent';
import {IUser} from "../../domain/models/user";

interface IState {
  loaded: boolean;
  globalContext: IGlobalContext;
}

class App extends React.Component<{}, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      globalContext: defaultContext
    };
  }

  async componentDidMount() {
    this.initiateTrackingListener();
    await this.initState();
    setTimeout(() => {
      this.setHelpCenter();
    }, 2000);
  }

  render() {
    return (
      <IntlSGWTProvider locale="en">
        <GlobalContext.Provider value={this.state.globalContext}>
          <Header/>

          <div className="d-md-none">
            <RouterComponent loaded={this.state.loaded} refreshGlobalContext={() => this.initState()}/>
          </div>

          <div className="d-none d-xl-block d-lg-block d-md-block d-xxl-block">
            <DesktopPageComponent/>
          </div>

          <HelpCenter/>
          <MiniFooter/>
        </GlobalContext.Provider>
      </IntlSGWTProvider>
    );
  }

  private initiateTrackingListener() {
    window.addEventListener('click', (evt: any) => {
      webTrackerService.trackClick(evt.srcElement);
    });
  }

  private async initState() {
    const requestCode = history.location.pathname.split('/')[1];

    if (!!requestCode) {
      this.setState({loaded: false, globalContext: defaultContext});
      const currentUser = await userResource.getCurrentUser()
      this.setState({globalContext: {...this.state.globalContext, currentUser}});
      const error = await getError(requestCode, this.state.globalContext.currentUser);
      if (error !== ErrorType.UNDEFINED) {
        redirectToErrorPage(error);
      } else {
        await this.setGlobalContext(requestCode);
      }
    }
    else {
      await this.setGlobalContext(requestCode);
    }
    this.setState({loaded: true});
  }

  private async setGlobalContext(requestCode: string) {
    const globalContext = await loadGlobalContext(requestCode);
    this.setState({globalContext: {...this.state.globalContext, ...globalContext}});
  }

  querySelector = (selectors: any) => document.querySelector(selectors);

  ticketEvent = (event: any) => {
    const ticket: ITicket = {
      attachments: [],
      createInProgress: true,
      readOnly: false,
      screenshot: null,
      showAlert: false,
      currentUrl: event.detail.message.url,
      urgency: event.detail.message.topic,
      description: event.detail.message.comment,
      summary: '[REQUEST][CREDIT APPROVAL PLATFORM] Ticket'
    }

    create(ticket).then(() => this.querySelector('#my-help-center').close());
  }

  private async setHelpCenter() {

    const i18n = {
      en: {
        messageOpenButtonLabel: 'Contact Support',
        messageTopicPlaceholder: 'Level of urgency',
        messageCommentPlaceholder: 'You need help? Please input details, such as ' +
                                   'Credit File number, description of request / issue, impacts, etc…',
        messageTopicGeneralEnquiry: 'Low',
        messageTopicReportATechnicalIssue: 'Medium',
        messageTopicMakeAComplaint: 'High',
        messageSentError: '',
        messageTopicPlaceholderMakeAComplaintHelper: ''
      }
    };
    const currentUserBasicInfo = await userResource.getUserBasicInfo(this.state.globalContext.currentUser.username);
    this.querySelector('#my-help-center').setUser({
                                                    username: currentUserBasicInfo.fullName,
                                                    email: this.state.globalContext.currentUser.email
                                                  });
    this.querySelector('#my-help-center').setI18n(i18n);
    this.querySelector('#my-help-center').addEventListener('sgwt-help-center--send', this.ticketEvent);
  }
}

async function getError(requestCode: string, currentUser: IUser) {
  if (!isRequestCodeValid(requestCode)) {
    return ErrorType.NOT_FOUND;
  }
  const currentUserHasAccess = await hasAccess(currentUser.username, requestCode);
  if (!currentUserHasAccess) {
    return ErrorType.ACCESS_DENIED;
  }
  return ErrorType.UNDEFINED;
}

export { App };
