import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import webTrackerService from "../../../utils/tracking/WebTrackerService";

export const RouteTracker = withRouter(
  class RouteTrackerHocWithoutRouter extends React.Component<RouteComponentProps<{}>, {}> {

    componentDidMount() {
      webTrackerService.trackEnterPage();
    }

    componentDidUpdate(prevProps: RouteComponentProps<{}>) {
      if (this.props.location !== prevProps.location) {
        webTrackerService.trackEnterPage();
      }
    }

    render() {
      return this.props.children;
    }
  }
);
