import React from "react";
import history from "../../../../context/History";

interface IProps {
  returnToDecisionPage: () => void;
}

export default class DecisionFailureComponent extends React.Component<IProps> {

  render() {
    return (
      <div className="container px-4">
        <div className="row">
          <div className="d-flex col">
            <div className="rounded-circle bg-danger-alpha-md d-flex mt-5 mb-3 align-items-center justify-content-center"
                 style={{width: "3rem", height: "3rem"}}>
              <span className="icon icon-md text-danger">warning</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2 className="text-danger mb-3">Your decision has not been submitted</h2>
          </div>
        </div>

        {
          !!history.location.state && !!history.location.state.errorMessage &&
          <div className="row">
              <div className="col">
                  <div className="text-large text-secondary mb-3">{history.location.state.errorMessage}</div>
              </div>
          </div>
        }

        <div className="row">
          <div className="col">
            <div className="text-large text-secondary mb-3">Please, go back to the decision page and resubmit your decision.</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div data-testid="buttonGoBackToDecisionPage" className="btn btn-block btn-xl sgbs-btn-default mt-3 mb-4" onClick={() => this.goBackToDecisionPage()}>
              Go back to decision page
            </div>
          </div>
        </div>
      </div>
    );
  }

  private goBackToDecisionPage() {
    this.props.returnToDecisionPage();
  }
}
