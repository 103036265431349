import PiwikReactRouter from 'piwik-react-router';

const url = process.env.REACT_APP_PIWIK_SERVER;
const siteId = process.env.REACT_APP_PIWIK_SITE_ID;

export const piwik = url && siteId ? PiwikReactRouter({url, siteId}) : undefined;

export function setUserId(userId: string, internal: boolean) {
  (window as any)._paq.push(['setUserId', userId]);
  (window as any)._paq.push(['setCustomVariable', 1, 'user', internal ? 'internal' : 'external', 'visit']);
}

export function trackEvent(event: string, value: string) {
  (window as any)._paq.push(['trackEvent', event, value]);
}
