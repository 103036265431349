import React from "react";

export class LoadingSpinner extends React.Component {
  render() {
    return (
      <div className="container px-4 text-center">
        <div className="spinner-grow mt-5">Loading...</div>
      </div>
    )
  }
}
