import { ErrorType } from '../components/App/common/ErrorType';
import history from "../context/History";

export function redirectToErrorPage(errorPath: ErrorType) {
  const url = history.location.pathname;
  if (!doesUrlContainsErrorPath(url)) {
    history.push(removeLastSlash(url) + errorPath);
  }
}

export function navigate(pathToAppend: string, state?: any) {
  const url = history.location.pathname;
  history.push({pathname: removeLastSlash(url) + pathToAppend, state});
}

export function removeLastSlash(url: string) {
  if (url.lastIndexOf("/") === url.length - 1) {
    return url.substring(0, url.length - 1);
  }
  return url;
}

export function isRequestCodeValid(requestCode: string) {
  const requestCodeRegexp = new RegExp('\\d{4}_\\d{2}_\\d{6}');
  return requestCodeRegexp.test(requestCode);
}

function doesUrlContainsErrorPath(url: string) {
  return url.includes("access-denied") || url.includes("not-found") || url.includes("internal-error") || url.includes("service-unavailable");
}
