import * as React from "react";
import { IFinancingRequestStatus } from '../../../../../domain/models/financing-request-model';
import { formatDateToString } from "../../../../../utils/conversion.service";

interface IProps {
  financingRequestStatus: IFinancingRequestStatus;
}

enum TypeBadge {
  SECONDARY = "badge-discreet-secondary",
  WARNING = "badge-discreet-warning",
  INFO = "badge-discreet-info",
  SUCCESS = "badge-discreet-success",
  DANGER = "badge-discreet-danger"
}

export const BADGE_SEPARATOR = "·";

export class FinancingRequestStatusBadgeComponent extends React.Component<IProps> {

  render() {
    return (<div className="text-large my-2">{factoryBadge(this.props.financingRequestStatus)}</div>)
  }

}

function factoryBadge({status, date, isSigning, deadLine}: IFinancingRequestStatus) {
  const dateFormatted = date ? formatDateToString(date) : undefined;
  const dateIsPassed = date && date.getTime() < (new Date()).getTime();
  const deadLineIsPassed = deadLine && date && deadLine.getTime() < date.getTime();
  let text: string;
  switch (status) {
    case "ABANDONED":
      text = date ? `Abandoned ${dateFormatted}` : "Abandoned";
      return renderBadge(text, TypeBadge.SECONDARY);
    case "RECALLED":
      return renderBadge("Recalled", TypeBadge.WARNING);
    case "DRAFT":
      return renderBadge(`Draft ${BADGE_SEPARATOR} Not submitted`, TypeBadge.SECONDARY);
    case "IN_PROGRESS":
      return renderBadge(`In Progress ${BADGE_SEPARATOR} Deadline ${dateFormatted}`, TypeBadge.INFO, dateIsPassed);
    case "RETURN_TO_STUDY":
      return renderBadge(`Returned to Study ${dateFormatted}`, TypeBadge.WARNING);
    case "FINALLY_APPROVED":
      text = `Approved ${BADGE_SEPARATOR} ` + (isSigning ? `Deal Signed ${dateFormatted}` : `Valid until ${dateFormatted}`);
      return renderBadge(text, TypeBadge.SUCCESS, (isSigning ? deadLineIsPassed : dateIsPassed));
    case "FINALLY_APPROVED_WITH_MODIFICATION":
      text = `Approved with Modifications ${BADGE_SEPARATOR} ` + (isSigning ? `Deal Signed ${dateFormatted}` : `Valid until ${dateFormatted}`);
      return renderBadge(text, TypeBadge.WARNING, (isSigning ? deadLineIsPassed : dateIsPassed));
    case "FINALLY_PARTIALLY_APPROVED" :
      text = `Partially Approved ${BADGE_SEPARATOR} ` + (isSigning ? `Deal Signed ${dateFormatted}` : `Valid until ${dateFormatted}`);
      return renderBadge(text, TypeBadge.WARNING, (isSigning ? deadLineIsPassed : dateIsPassed));
    case "FINALLY_REFUSED":
      return renderBadge("Refused", TypeBadge.DANGER);
  }
}

function renderBadge(text: string, typeBadge: TypeBadge, displayWarningIcon?: boolean) {
  return <div data-testid="badge-request-status" className={`badge badge-pill ${typeBadge}`}>
    <span>{text}</span>
    {displayWarningIcon && <i data-testid="warning-icon" className="icon icon-sm ml-1">warning</i>}
  </div>;
}

