import { get } from '../../utils/axios/api';

const DOMAIN = (process.env.NODE_ENV === 'development') ? 'https://cav-hom-visibility.fr.world.socgen' : '/visibility';
export const BASE_URL = 'api/v1/visibilities';

export class VisibilityResource {

  domain: string;

  constructor(domain = DOMAIN) {
    this.domain = domain
  }

  async hasAccessToRequest(userName: string, requestCode: string): Promise<boolean> {
    try {
      const response = await get(`${this.domain}/${BASE_URL}/access`,
        {'requestCode': requestCode, 'userName': userName});
      return response.data.hasRight;
    } catch (error) {
      return error;
    }
  }
}

export const visibilityResource = new VisibilityResource();
