import React from 'react';
import { defaultFinancingRequest, IFinancingRequest } from '../domain/models/financing-request-model';
import { IMarketRequest } from '../domain/models/market-request-model';
import { RequestType } from '../domain/models/request-type';
import { emptyUser, emptyUserBasicInfo, IUser, IUserBasicInfo } from '../domain/models/user';
import {IFinancingLimitRequest} from "../domain/models/financing-limit-request.models";

export interface IGlobalContext {
  request: IFinancingRequest | IMarketRequest | IFinancingLimitRequest;
  requestType: RequestType;
  currentUser: IUser;
  initiator: IUserBasicInfo;
}

export interface IPropsWithGlobalContext {
  globalContext: IGlobalContext;
}

export const defaultContext: IGlobalContext = {
  currentUser: emptyUser,
  initiator: emptyUserBasicInfo,
  request: defaultFinancingRequest,
  requestType: RequestType.FINANCING,
};

export const GlobalContext = React.createContext<IGlobalContext>(defaultContext);

// HOC to provide the globalContext to the wrapped component.
// Since TypeScript 3.2, there is an issue with HOC.
// It may be due to a bug in TypeScript itself... So using `any` "solves" the issue...
// cf. https://stackoverflow.com/questions/53556160/react-hoc-and-typescript-3-2
export function withGlobalContext<P extends IPropsWithGlobalContext>(
  WrappedComponent: React.ComponentType<P>
  // ): React.ComponentType<Pick<P, Exclude<keyof P, keyof IPropsWithGlobalContext>>> {
): React.ComponentType<any> {
  return props => (
    <GlobalContext.Consumer>
      {(globalContext: IGlobalContext) => <WrappedComponent globalContext={globalContext} {...props} />}
    </GlobalContext.Consumer>
  );
}
