import { get } from '../../utils/axios/api';

const DOMAIN = (process.env.NODE_ENV === 'development') ? 'https://cav-hom-exchange-rate.fr.world.socgen' : '/exchange-rate';
const EXCHANGE_RATE_URL = 'api/v1/exchange-rates';

export class ExchangeRateResource {
  private readonly domain: string;

  constructor(domain = DOMAIN) {
    this.domain = domain
  }

  async getExchangeRates(toCurrency: string, fromCurrencies: string[]) {
    try {
      const response = await get(`${this.domain}/${EXCHANGE_RATE_URL}/latest-previous-month/to/${toCurrency}`, {from: fromCurrencies});
      return response.data;
    } catch (error) {
      return error;
    }
  }
}

const exchangeRateResource = new ExchangeRateResource();
export default exchangeRateResource;
