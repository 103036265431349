import React from "react";

interface IProps {
  handleSubmitDecision: () => void;
}

export class ButtonsDecisionComponent extends React.Component<IProps> {

  render() {
    return (
      <div data-testid="ButtonsDecisionComponent" className="row">
        <div className="col">
          <button data-testid="submitDecisionButton"
                  data-tracking-tag="mobile-decision-submit"
                  className="btn btn-block btn-xl btn-primary my-0"
                  onClick={() => this.props.handleSubmitDecision()}>
            Submit your decision
          </button>
        </div>
      </div>
    )
  }

}
