import React from "react";

interface IProps {
  returnToDealInfoPage: () => void;
}

export class UnavailableErrorComponent extends React.Component<IProps> {

  render() {
    return (
      <div data-testid="UnavailableErrorComponent" className="container-fluid">
        <div className="container main-wrapper">
          <div className="row">
            <div className="col-lg-2 bg-white pt-5 pb-4 py-lg-0">
                  <span className="d-block text-outline-socgen text-primary-alt font-weight-medium"
                        style={{fontSize: "56px", fontFamily: "Neue Haas Grotesk W04 Disp"}}>503</span>
            </div>
            <div className="col-lg-5 bg-white d-flex flex-column justify-content-between">
              <div>
                <h1 className="display-3 font-weight-medium line-height-1 py-2">We'll be back shortly.</h1>
                <p className="mb-5 h3 font-weight-medium text-secondary line-height-1">This service is currently undergoing maintenance.</p>
              </div>
            </div>
          </div>
          <div className="d-block d-md-none">
            <button data-testid="goBackButton" className="btn btn-xl btn-primary btn-block mb-1" onClick={() => this.goBack()}>Refresh</button>
          </div>
        </div>
      </div>
    )
  }

  private goBack() {
    this.props.returnToDealInfoPage();
  }
}
