import {IAggregateAmount} from 'line-aggregator/dist/src/models/IAggregateAmount';
import IAggregateTenor from 'line-aggregator/dist/src/models/IAggregateTenor';
import IAmount from 'line-aggregator/dist/src/models/IAmount';
import ITenor from 'line-aggregator/dist/src/models/ITenor';
import * as React from 'react';
import {GlobalContext, IGlobalContext} from '../../../../../context/global.context';
import {IDealTeamRiskSharing} from '../../../../../domain/models/financing-request-model';
import {abbreviateNumber} from '../../../../../utils/conversion.service';
import {
  IFinancingLimitRequest
} from "../../../../../domain/models/financing-limit-request.models";
import {getLastDecision} from "../../../../../domain/services/decision.service";
import financingLimitAggregator from "../../../../../utils/aggregator/FinancingLimitAggregator";
import {DecisionType} from "../../../../../domain/models/decision-type";
import {decisionResource} from "../../../../../domain/resources/decision.resource";

interface IState {
  limitAmount?: IAggregateAmount,
  maxTenor?: IAggregateTenor | string,
  errorMessage?: string,
  ceilingDecisionType ?: DecisionType;
}

export default class FinancingLimitRequestSummaryComponent extends React.Component<{}, IState> {

  static contextType: React.Context<IGlobalContext> = GlobalContext;

  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const request = this.context.request as IFinancingLimitRequest;
    try {
      if (request.type !== 'AMENDMENT/WAIVER' && request.type !== 'DEAL_ANNUAL_REVIEW') {
        const lastDecision = await getLastDecision(request.code);
        const financingLimitDecisions = lastDecision ? lastDecision.financingLimitDecisions : undefined;
        const {financingLimits, dealCurrency} = request as IFinancingLimitRequest;
        const [limitAmount, maxTenor] = await Promise.all(
          [financingLimitAggregator.getLimitTotalAmount(financingLimits, dealCurrency, financingLimitDecisions),request.dealMaturity]);
        this.setState({limitAmount, maxTenor});
      }
      else {
        const {finalTake, dealCurrency, dealMaturity} = request as IFinancingLimitRequest;
        const limitAmount = {initial: {currency: dealCurrency, value: finalTake}};
        this.setState({limitAmount, maxTenor: dealMaturity});
      }

      const lastCeilingDecision = await Promise.resolve(decisionResource.getCeilingDecisionHistory(request.code));
      const ceilingDecisionType = (lastCeilingDecision ? lastCeilingDecision.type : (request.ceiling ? DecisionType.APPROVAL : undefined));
      this.setState({ceilingDecisionType});

    } catch (error) {
      console.error('Error loading last decision and exchange rates', error);
      this.setState({...this.state, errorMessage: 'Not able to compute this value.'});
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <h3 className="mb-1 mt-4">Request summary</h3>
          </div>
        </div>

        <div className="card card-bordered my-2 p-3">
          {this.state.errorMessage
           || <>
             <div className="row mb-4">
               <div className="col-6">
                 <div className="text-large text-secondary font-weight-medium">Total Limit</div>
                 {getLimitAmountRender(this.state)}
               </div>
               <div className="col-6">
                 <div className="text-large text-secondary font-weight-medium">Max tenor</div>
                 {getMaxTenorRender(this.state)}
               </div>
             </div>
             <div className="row">
               <div className="col-12">
                 <div className="text-large text-secondary font-weight-medium">Risk sharing</div>
                 {
                   this.context.request.dealTeamRiskSharings.map((dealTeamRiskSharing: IDealTeamRiskSharing) =>
                                                                   <div key={dealTeamRiskSharing.businessLineName} className="text-large">
                                                                     {dealTeamRiskSharing.businessLineName} ({dealTeamRiskSharing.businessLinePercentage} %)
                                                                   </div>
                   )
                 }
               </div>
             </div>
           </>
          }
        </div>
      </div>
    )
  }
}

const getLimitAmountRender = ({limitAmount,ceilingDecisionType}: IState) => {
  if (!limitAmount) {
    return <div data-testid="LimitAmount" className="text-large">-</div>
  }
  if (limitAmount.modified && amountAreNotEquals(limitAmount.modified, limitAmount.initial)) {
    return <div data-testid="LimitAmount" className="text-large text-info">
      {limitAmount.modified.currency} {abbreviateNumber(limitAmount.modified.value)}
      {getCeilingIconRender(ceilingDecisionType)}
    </div>
  }
  return <div data-testid="LimitAmount" className="text-large">
    {limitAmount.initial.currency} {abbreviateNumber(limitAmount.initial.value)}
    {getCeilingIconRender(ceilingDecisionType)}
  </div>
};

const getCeilingIconRender = (ceilingDecisionType : DecisionType | undefined) => {
  if (ceilingDecisionType && ceilingDecisionType !== DecisionType.REFUSAL) {
    return <div data-testid="CeilingiconId" className="ceiling-icon-style">
      <i className="material-icons ceiling-icon">vertical_align_top</i>
    </div>
  }
    return <div></div>
};

const getMaxTenorRender = ({maxTenor}: IState) => {

  if (!maxTenor) {
    return <div data-testid="MaxTenor" className="text-large">-</div>
  }

  if (maxTenor && typeof maxTenor === 'string') {
    return <div data-testid="MaxTenor" className="text-large">
      {maxTenor}
    </div>
  }
  else {
    const tenor = maxTenor as IAggregateTenor
    if (tenor.modified && tenorsAreNotEquals(tenor.modified, tenor.initial)) {
      return <div data-testid="MaxTenor" className="text-large text-info">
        {formatTenorToString(tenor.modified)}
      </div>
    }
    return <div data-testid="MaxTenor" className="text-large">
      {formatTenorToString(tenor.initial)}
    </div>
  }
};

const amountAreNotEquals = (amount1: IAmount, amount2: IAmount) =>
  amount1.currency !== amount2.currency
  || amount1.value !== amount2.value;

const tenorsAreNotEquals = (tenor1: ITenor, tenor2: ITenor) =>
  tenor1.years !== tenor2.years
  || tenor1.months !== tenor2.months
  || tenor1.days !== tenor2.days;

const formatTenorToString = ({years, months, days}: ITenor) => `${years}Y ${months}M ${days}D`;
