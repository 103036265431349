import * as React from 'react';
import {IRequestVersionHistory } from '../../../../../domain/models/financing-request-model';
import { requestResource } from '../../../../../domain/resources/request.resource';
import { withLoadingComponent } from '../../../common/WithLoadingComponent';
import {FinancingLimitRequestStatusBadgeComponent} from "../FinanceLimitRequestStatusBadge/FinancingLimitRequestStatusBadgeComponent";
import {getFinancingLimitRequestStatus} from "../../../../../domain/services/financing-limit-request-status.service";
import {IFinancingLimitRequest} from "../../../../../domain/models/financing-limit-request.models";

interface IState {
  loaded: boolean;
  type?: string
  regularized?: boolean;
  description?: string;
}

interface IProps {
  request: IFinancingLimitRequest
}

export default class FinancingLimitHeaderComponent extends React.Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      type: undefined,
      regularized: undefined,
      description: undefined
    }
  }

  async componentDidMount() {
    const requestVersionDetails = await requestResource.getFinancingRequestVersionDetails(this.props.request.code);
    const {type, regularized, description} = requestVersionDetails.requestVersions.reduce(
      (a: IRequestVersionHistory, b: IRequestVersionHistory) => a.requestVersion < b.requestVersion ? b : a);
    this.setState({loaded: true, type, regularized, description});
  }

  render() {
    const DealHeaderWithLoading = withLoadingComponent(() => this.getDealHeaderData());
    return <DealHeaderWithLoading loaded={this.state.loaded}/>
  }

  private getDealHeaderData() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="text-large text-secondary font-weight-medium mt-4 mb-3">Financing Limit credit file</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="display-4 my-0">{this.props.request.name}</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="text-xlarge my-0">{this.state.type} · {this.state.description}</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FinancingLimitRequestStatusBadgeComponent financingRequestStatus={getFinancingLimitRequestStatus(this.props.request)}/>
          </div>
        </div>

      </div>
    );
  }
}
