import React from 'react';
import { GlobalContext } from '../../../../context/global.context';
import { Delegation } from '../../../../domain/models/delegation';
import { ILatestDecision } from '../../../../domain/models/latest-decision-model';
import { defaultDecisionMaker, IPotentialDecisionMaker } from '../../../../domain/models/potential-decision-maker';
import { isPostApprovalDecision, submitDecision, submitDecisionOnPostApproval } from '../../../../domain/services/decision.service';
import { getRequestVersion } from '../../../../domain/services/request.service';
import webTrackerService from '../../../../utils/tracking/WebTrackerService';
import { navigate } from '../../../../utils/util.service';
import { AlertDecision } from './AlertDecision/AlertDecision';
import { ButtonsDecisionComponent } from './ButtonsDecision/ButtonsDecisionComponent';
import { CommentDecisionComponent } from './CommentDecision/CommentDecisionComponent';
import { CreditAuthorityComponent } from './CreditAuthority/CreditAuthorityComponent';
import { OnBehalfComponent } from './OnBehalf/OnBehalfComponent';
import { Decision, SelectDecisionComponent } from './SelectDecision/SelectDecision';
import { SigningAuthorityComponent } from './SigningAuthority/SigningAuthorityComponent';

interface IProps {
  latestDecision: ILatestDecision;
  potentialDecisionMakers: IPotentialDecisionMaker[];
}

interface IState {
  decisionMaker: IPotentialDecisionMaker;
  delegation: Delegation;
  decision: Decision;
  comment: string;
  formErrors: string[];
}

export class MakeDecisionComponent extends React.Component<IProps, IState> {

  static contextType = GlobalContext;

  constructor(props: IProps) {
    super(props);
    this.state = {
      decisionMaker: defaultDecisionMaker,
      delegation: Delegation.UNDEFINED,
      decision: Decision.UNDEFINED,
      comment: '',
      formErrors: [],
    }
  }

  componentDidMount() {
    if (this.doesPotentialDecisionMakersContainsOnlyCurrentUser()) {
      this.setDecisionMaker(this.props.potentialDecisionMakers[0]);
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <h3 className="mb-1 mt-4">2. Make your decision</h3>
          </div>
        </div>
        {
          !this.doesPotentialDecisionMakersContainsOnlyCurrentUser() &&
          <OnBehalfComponent potentialDecisionMakers={this.props.potentialDecisionMakers}
                             selectRepresented={(event: any) => this.handleRepresentedSelection(event)}
                             setCurrentUserAsDecisionMaker={() => this.setCurrentUserAsDecisionMaker()}
                             removeDecisionMaker={() => this.removeDecisionMaker()}/>}
        <SelectDecisionComponent latestDecision={this.props.latestDecision} handleDecisionChoice={(decision: Decision) => this.handleDecisionChoice(decision)}/>
        {
          this.state.decisionMaker.hasSigningAuthority && !this.state.decisionMaker.isFinalApprover && this.state.decision !== Decision.RETURN_TO_STUDY &&
          <SigningAuthorityComponent delegation={this.state.delegation}
                                     handleSigningChoice={(delegation: Delegation) => this.handleDelegationChoice(delegation)}/>
        }
        {
          this.state.decisionMaker.isFinalApprover && this.state.decision !== Decision.RETURN_TO_STUDY &&
          <CreditAuthorityComponent delegation={this.state.delegation}
                                    handleCreditChoice={(delegation: Delegation) => this.handleDelegationChoice(delegation)}/>
        }
        {this.decisionMakerDoesntRejectCreditAuthority() && <CommentDecisionComponent onCommentChange={(comment: string) => this.onCommentChange(comment)}/>}
        {this.state.formErrors.length > 0 && <AlertDecision reasons={this.state.formErrors}/>}
        {this.decisionMakerDoesntRejectCreditAuthority() && <ButtonsDecisionComponent handleSubmitDecision={() => this.handleSubmitDecision()}/>}
      </div>
    );
  }

  private async handleSubmitDecision() {
    const formErrors = this.getFormErrors();
    this.setState({formErrors});
    if (formErrors.length === 0) {
      try {
        if (isPostApprovalDecision(this.context)) {
          await submitDecisionOnPostApproval(this.context.request.code,
                                             getRequestVersion(this.context.requestType, this.context.request),
                                             this.context.request.workflowId,
                                             this.state.delegation,
                                             this.state.comment,
                                             this.state.decisionMaker,
                                             this.context.currentUser,
                                             this.state.decision);
        } else {
          await submitDecision(this.context.request.code,
                               getRequestVersion(this.context.requestType, this.context.request),
                               this.context.request.workflowId,
                               this.state.delegation,
                               this.state.comment,
                               this.state.decisionMaker,
                               this.context.currentUser,
                               this.state.decision,
                               this.props.latestDecision.requestDecisionId);
        }
        navigateToSuccessPage();
      } catch (error) {
        navigateToDecisionFailurePage(error.response.data.message);
      }
    } else {
      webTrackerService.track('decisionSubmitError', {title: 'decision-submit-error', errors: formErrors.join(', ')})
    }
  }

  private getFormErrors() {
    const reasons = [];
    if (this.state.comment === '') {
      reasons.push('Comment is mandatory');
    }
    if (this.state.decisionMaker.username === '') {
      reasons.push('Decision Maker is not selected');
    }
    if (this.state.decision === Decision.UNDEFINED) {
      reasons.push('Decision is not selected');
    }
    if (this.state.delegation === Delegation.UNDEFINED) {
      reasons.push('Delegation is not selected');
    }
    return reasons;
  }

  private handleRepresentedSelection(event: any) {
    const selectedRepresented = this.props.potentialDecisionMakers.find(potentialDecisionMaker => potentialDecisionMaker.username === event.target.value);
    if (!!selectedRepresented) {
      this.setDecisionMaker(selectedRepresented);
    }
  }

  private setCurrentUserAsDecisionMaker() {
    const currentUser = this.props.potentialDecisionMakers.find(potentialDecisionMaker => potentialDecisionMaker.username === this.context.currentUser.username);
    if (!!currentUser) {
      this.setDecisionMaker(currentUser);
    }
  }

  private setDecisionMaker(decisionMaker: IPotentialDecisionMaker) {
    this.setState({decisionMaker});
    if (!decisionMaker.hasSigningAuthority && !decisionMaker.isFinalApprover) {
      this.setState({delegation: Delegation.RECOMMENDATION});
    } else {
      this.setState({delegation: Delegation.UNDEFINED});
    }
  }

  private removeDecisionMaker() {
    this.setState({decisionMaker: defaultDecisionMaker, delegation: Delegation.UNDEFINED});
  }

  private handleDelegationChoice(delegation: Delegation) {
    this.setState({delegation});
  }

  private decisionMakerDoesntRejectCreditAuthority() {
    return !this.state.decisionMaker.isFinalApprover || (this.state.decisionMaker.isFinalApprover && this.state.delegation !== Delegation.RECOMMENDATION);
  }

  private doesPotentialDecisionMakersContainsOnlyCurrentUser() {
    return this.props.potentialDecisionMakers.length === 1 && this.props.potentialDecisionMakers[0].username === this.context.currentUser.username;
  }

  private handleDecisionChoice(decision: Decision) {
    this.setState({decision});
    if (decision === Decision.RETURN_TO_STUDY) {
      this.setState({delegation: Delegation.RECOMMENDATION});
    }
  }

  private onCommentChange(comment: string) {
    this.setState({comment});
  }
}

function navigateToSuccessPage() {
  navigate('/success');
}

function navigateToDecisionFailurePage(errorMessage: string) {
  navigate('/failure', {errorMessage});
}
