import {IAggregateAmount} from 'line-aggregator/dist/src/models/IAggregateAmount';
import IAggregateTenor from 'line-aggregator/dist/src/models/IAggregateTenor';
import IAmount from 'line-aggregator/dist/src/models/IAmount';
import ITenor from 'line-aggregator/dist/src/models/ITenor';
import * as React from 'react';
import {GlobalContext} from '../../../../../context/global.context';
import {getLastDecision} from '../../../../../domain/services/decision.service';
import marketLineAggregator from '../../../../../utils/aggregator/MarketLineAggregator';
import {abbreviateNumber} from '../../../../../utils/conversion.service';
import {duration, ILegalEntityDetails} from "../../../../../domain/models/typings";

interface IState {
  sgFinalTakeAmount?: IAggregateAmount;
  tenor?: IAggregateTenor;
  errorMessage?: string;
}

export default class MarketRequestSummaryComponent extends React.Component<{}, IState> {

  static contextType = GlobalContext;

  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const {request: {code, marketLines}} = this.context;
    try {
      const lastDecision = await getLastDecision(code);
      const marketLineDecisions = lastDecision ? lastDecision.marketLineDecisions : undefined;
      const [maxCvar, tenor] = await Promise.all([marketLineAggregator.getMaxCvar(marketLines, marketLineDecisions),
                                                  marketLineAggregator.getTenorOfMaxCvar(marketLines, marketLineDecisions)]);
      this.setState({sgFinalTakeAmount: maxCvar, tenor})
    } catch (error) {
      console.error('Error loading last decision and exchange rates', error);
      this.setState({...this.state, errorMessage: 'Not able to compute this value.'});
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <h3 className="mb-1 mt-4">Request summary</h3>
          </div>
        </div>

        <div className="card card-bordered my-2 p-3">
          {this.state.errorMessage
            || <>
              <div className="row mb-4">
                <div className="col-6">
                  <div className="text-large text-secondary font-weight-medium">Max CVAR</div>
                  {getMaxCvarRender(this.state)}
                </div>
                <div className="col-6">
                  <div className="text-large text-secondary font-weight-medium">Tenor</div>
                  {getTenorOfMaxCvarRender(this.state, this.context.request.legalEntityDetail)}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="text-large text-secondary font-weight-medium">SG market share</div>
                  <div className="text-large">{this.context.request.sgMarketShare} %</div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }
}

const getMaxCvarRender = ({sgFinalTakeAmount}: IState) => {
  if (!sgFinalTakeAmount) {
    return <div data-testid="MaxCvar" className="text-large">-</div>
  }
  if (sgFinalTakeAmount.modified && amountAreNotEquals(sgFinalTakeAmount.modified, sgFinalTakeAmount.initial)) {
    return <div data-testid="MaxCvar" className="text-large text-info">
      {sgFinalTakeAmount.modified.currency} {abbreviateNumber(sgFinalTakeAmount.modified.value)}
    </div>
  }
  return <div data-testid="MaxCvar" className="text-large">
    {sgFinalTakeAmount.initial.currency} {abbreviateNumber(sgFinalTakeAmount.initial.value)}
  </div>
};

const getTenorOfMaxCvarRender = (state: IState,legalEntityDetail: ILegalEntityDetails[] ) => {
  if (isMarketMultiLegalEntityRequest(legalEntityDetail) && state.sgFinalTakeAmount !== undefined && state.sgFinalTakeAmount.initial.interval) {
    if (state.sgFinalTakeAmount.initial.interval === '30Y+') {
      return formatTenorToString(duration(50, 0, 0));
    } else {
      return formatTenorToString(getDuration(state.sgFinalTakeAmount.initial.interval.substr(state.sgFinalTakeAmount.initial.interval.indexOf('-') + 1)));
    }
  } else {
    if (!state.tenor) {
      return <div data-testid="TenorOfMaxCvar" className="text-large">-</div>
    }
    if (state.tenor.modified && tenorsAreNotEquals(state.tenor.modified, state.tenor.initial)) {
      return <div data-testid="TenorOfMaxCvar" className="text-large text-info">
        {formatTenorToString(state.tenor.modified)}
      </div>
    }
    return <div data-testid="TenorOfMaxCvar" className="text-large">
      {formatTenorToString(state.tenor.initial)}
    </div>;
  }
};

const amountAreNotEquals = (amount1: IAmount, amount2: IAmount) =>
  amount1.currency !== amount2.currency
  || amount1.value !== amount2.value;

const tenorsAreNotEquals = (tenor1: ITenor, tenor2: ITenor) =>
  tenor1.years !== tenor2.years
  || tenor1.months !== tenor2.months
  || tenor1.days !== tenor2.days;

const formatTenorToString = ({years, months, days}: ITenor) => `${years}Y ${months}M ${days}D`;

const getDuration =(durationDetail: string)=> {
  if (durationDetail.indexOf('Y') > 0) {
    return duration(Number(durationDetail.substring(0, durationDetail.indexOf('Y'))), 0, 0);
  } else if (durationDetail.indexOf('M') > 0) {
    return duration(0, Number(durationDetail.substring(0, durationDetail.indexOf('M'))), 0);
  } else {
 //   if (durationDetail.indexOf('D') > 0) {
    return duration(0, 0, Number(durationDetail.substring(0, durationDetail.indexOf('D'))));
  }
}

const isMarketMultiLegalEntityRequest= (legalEntityDetail: ILegalEntityDetails[]) => {
  return  legalEntityDetail !== undefined && legalEntityDetail.length > 0
}
