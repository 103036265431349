import React from "react";
import { GlobalContext } from "../../../../../context/global.context";
import { IPotentialDecisionMaker } from "../../../../../domain/models/potential-decision-maker";

enum ToggleValue {
  YES,
  NO,
  UNDEFINED
}

interface IProps {
  potentialDecisionMakers: IPotentialDecisionMaker[];
  selectRepresented: (event: any) => void;
  setCurrentUserAsDecisionMaker: () => void;
  removeDecisionMaker: () => void;
}

interface IState {
  onBehalfToggle: number;
}

export class OnBehalfComponent extends React.Component<IProps, IState> {

  static contextType = GlobalContext;

  constructor(props: IProps) {
    super(props);
    this.state = {
      onBehalfToggle: ToggleValue.UNDEFINED,
    }
  }

  render() {
    return (
      <div data-testid="OnBehalfComponent" className="mb-4">
        <div className="row">
          <div className="col">
            <div className="text-xlarge text-secondary mt-2 mb-2">{this.getOnBehalfMessage()}</div>
          </div>
        </div>

        {
          this.doesPotentialDecisionMakersContainCurrentUser() &&
          <div className="row">
              <div className="col btn-group btn-group-toggle my-2">
                  <button data-testid="onBehalfYes"
                          data-tracking-tag="mobile-onbehalf-yes"
                          className={getYesClass(this.state.onBehalfToggle)}
                          onClick={() => this.handleYesClick()}>Yes</button>
                  <button data-testid="onBehalfNo"
                          data-tracking-tag="mobile-onbehalf-no"
                          className={getNoClass(this.state.onBehalfToggle)}
                          onClick={() =>  this.handleNoClick()}>No</button>
              </div>
          </div>
        }

        {
          (this.state.onBehalfToggle === ToggleValue.YES || !this.doesPotentialDecisionMakersContainCurrentUser()) &&
          <div className="row">
              <div className="col">
                  <select data-testid="selectOnBehalf"
                          className="form-control form-control-xl my-2"
                          onChange={this.props.selectRepresented}
                          defaultValue={""}>
                      <option value={""} disabled hidden>Select the represented user</option>
                    {
                      this.props.potentialDecisionMakers
                          .filter(potentialDecisionMaker => potentialDecisionMaker.username !== this.context.currentUser.username)
                          .map((user: IPotentialDecisionMaker) => {
                            return <option key={user.username} value={user.username}>{user.fullName} ({user.teamLabel})</option>;
                          })
                    }
                  </select>
              </div>
          </div>
        }
      </div>
    )
  }

  private handleYesClick() {
    this.setState( {onBehalfToggle: ToggleValue.YES});
    this.props.removeDecisionMaker();
  }

  private handleNoClick() {
    this.setState( {onBehalfToggle: ToggleValue.NO});
    this.props.setCurrentUserAsDecisionMaker();
  }

  private getOnBehalfMessage(): string {
    if (this.doesPotentialDecisionMakersContainCurrentUser()) {
      return "Do you want to make this decision on behalf of someone else ?";
    }
    return "Your decision can only be made on behalf of someone else";
  }

  private doesPotentialDecisionMakersContainCurrentUser() {
    return this.props.potentialDecisionMakers.map(potentialDecisionMaker => potentialDecisionMaker.username).indexOf(this.context.currentUser.username) !== -1;
  }
}

function getYesClass(toggleValue: ToggleValue) {
  if (toggleValue === ToggleValue.YES) {
    return "btn btn-xl btn-toggle-primary active";
  }
  return "btn btn-xl btn-toggle-primary";
}

function getNoClass(toggleValue: ToggleValue) {
  if (toggleValue === ToggleValue.NO) {
    return "btn btn-xl btn-toggle-primary active";
  }
  return "btn btn-xl btn-toggle-primary";
}
