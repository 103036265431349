import React from "react";
import { Delegation } from "../../../../../domain/models/delegation";

interface IProps {
  handleSigningChoice: (signingChoice: Delegation) => void;
  delegation: Delegation;
}

export class SigningAuthorityComponent extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
    this.getButtonClass.bind(this);
  }

  render() {
    return (
      <div data-testid="SigningAuthorityComponent" className="mb-4">
        <div className="row">
          <div className="col">
            <div className="text-xlarge text-secondary mb-2">Do you want to engage your signing authority ?</div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="btn-group btn-block btn-group-toggle my-2">
              <button data-testid="signingAuthorityYes"
                      data-tracking-tag="mobile-signing-yes"
                      className={this.getButtonClass(Delegation.SIGNING_AUTHORITY)}
                      onClick={() => this.props.handleSigningChoice(Delegation.SIGNING_AUTHORITY)}>Yes
              </button>
              <button data-testid="signingAuthorityNo"
                      data-tracking-tag="mobile-signing-no"
                      className={this.getButtonClass(Delegation.RECOMMENDATION)}
                      onClick={() => this.props.handleSigningChoice(Delegation.RECOMMENDATION)}>No
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private getButtonClass(delegation: Delegation) {
    return "btn btn-xl btn-toggle-primary " + (this.props.delegation === delegation ? "active" : "");
  }
}
