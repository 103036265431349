import * as React from 'react';
import {GlobalContext} from '../../../../context/global.context'
import {RequestType} from '../../../../domain/models/request-type';
import FinancingDealHeaderComponent from './FinancingDealHeaderComponent/FinancingDealHeaderComponent';
import MarketDealHeaderComponent from './MarketDealHeaderComponent/MarketDealHeaderComponent';
import FinancingLimitHeaderComponent from './FinancingLimitHeaderComponent/FinancingLimitHeaderComponent';

export default class DealHeaderComponent extends React.Component<{}> {

  static contextType = GlobalContext;

  render() {
    return this.context.requestType === RequestType.FINANCING
      ? <FinancingDealHeaderComponent request={this.context.request}/>
      : this.context.requestType === RequestType.FINANCING_LIMIT
        ? <FinancingLimitHeaderComponent request={this.context.request}/>
        : <MarketDealHeaderComponent request={this.context.request}/>
  }
}
