export type Callback = (error: any, claims: { origin_network: string }) => void
type FetchUserInfo = (callback: Callback) => void

export const networkRedirection = async (currentLocation: string, fetchUserInfo: FetchUserInfo, redirectionUrl: string): Promise<boolean> => {

  const fetchUserInfoToPromise = convertToPromise(fetchUserInfo);

  let isRedirecting = false;

  const requestCode = currentLocation.match(/\d{4}_\d{2}_\d{6}/);
  try {
    const userInfo = await fetchUserInfoToPromise();
    if (userInfo.origin_network === 'LAN' && requestCode && window.screen.width >= 768) {
      window.location.replace(`${redirectionUrl}/#/credit-file/${requestCode}`)
      isRedirecting = true;
    }
  } catch (error) {
    console.error('Not able to define the user network.', error)
  }
  return isRedirecting
};

function convertToPromise(fetchUserInfo: FetchUserInfo) {
  return (): Promise<{ origin_network: string }> => {
    return new Promise((resolve, reject) => {
      fetchUserInfo((error: any, response: { origin_network: string }) => {
        if (error) {
          reject(error);
        } else {
          resolve(response)
        }
      });
    });
  };
}
